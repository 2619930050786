import React, { useEffect, useState } from "react";

const Counter = ({ target, isVisible }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!isVisible) return;
    const increment = Math.ceil(target / 100); 
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < target) {
          return Math.min(prevCount + increment, target);
        } else {
          clearInterval(interval);
          return target;
        }
      });
    }, 20); 

    return () => clearInterval(interval);
  }, [target,isVisible]);

  return <span>{count}</span>;
};

export default Counter;
