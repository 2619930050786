import React, { Suspense } from "react";
import { Shimmer } from "../component/Shimmer";
import Coustomercentric from "../assets/Images/aboutus/Customer Centric Icon-01.svg";
import InnovationIcon from "../assets/Images/aboutus/Innovation-01.svg";

const AboutUs = () => {
  // const [slideIn, setSlideIn] = useState(false);

  // useEffect(() => {
  //   setSlideIn(true);
  // }, []);
  return (
    <div className=" flex flex-col items-center py-10 text-[#011e41] font-['Albert_Sans']">
      <div className="flex flex-col items-center py-10 mt-5 ">
        {/* Header */}
        <h1 className="text-3xl md:text-[50px] font-black md:font-extrabold font-['Albert_Sans'] mb-[53px]">
          OUR STORY
        </h1>

        {/* Paragraphs */}
        <div className="px-3 md:px-0 md:w-10/12 text-center md:text-justify space-y-8">
          <p className="text-lg md:text-[22px] font-base font-['Albert_Sans']">
            The inception of our journey can be traced back to a significant
            challenge encountered by our founders—a predicament familiar to many
            international travelers: the prolonged waiting times for visa
            appointments to travel from the United Kingdom to Schengen
            countries, with the next available appointment being three months
            away. Recognizing that this logistical bottleneck was a pervasive
            issue faced by countless individuals globally, our founders were
            compelled to conceptualize a more effective approach. They
            understood that this challenge was not merely an inconvenience but a
            barrier that prevented people from pursuing important
            opportunities—whether for work, education, family, or leisure.
            Driven by the imperative to alleviate this impediment, they
            intensively investigated how technological advancements could be
            employed to surmount these obstacles.
          </p>

          <p className="text-lg md:text-[22px] font-base  font-['Albert_Sans']">
            After months of research and countless hours of brainstorming, they
            began to understand the intricacies of visa processing across
            different countries and the technological gaps that contributed to
            these delays. For many months, they engaged in comprehensive
            research, intricate problem-solving, and methodical integration of
            technology, ultimately devising a robust solution to these systemic
            inefficiencies. Their work was not only about technology but also
            about understanding the needs of travelers and how best to serve
            them. This breakthrough marked the genesis of The Flying Panda—a
            transformative initiative committed to enhancing the accessibility,
            efficiency, and convenience of visa application processes and travel
            documentation.
          </p>

          <p className="text-lg md:text-[22px] font-base font-['Albert_Sans']">
            The Flying Panda started with a vision of providing solutions that
            were simple yet powerful, and the core of our approach has always
            been the belief that travel should be accessible to all. From our
            humble beginnings to becoming a trusted name in the travel services
            industry, our journey has been fueled by a passion for helping
            people navigate complex visa processes with ease. We have
            successfully helped thousands of travelers achieve their dreams, and
            our dedication to excellence continues to drive us forward.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center md:w-10/12 justify-between gap-[60px] py-10 md:py-0 md:mt-[160px]">
        {/* Text Section */}
        <div className="md:w-6/12">
          <h2 className="text-3xl md:text-[40px] text-center md:text-left font-black md:font-extrabold font-['Albert_Sans'] mb-5 md:mb-[53px]">
            OUR MISSION
          </h2>
          <p className="px-5 md:px-0 text-center md:text-left font-['Albert_Sans'] font-medium text-[20px]">
            Today, The Flying Panda harnesses cutting-edge technologies to
            streamline visa acquisition, mitigate bureaucratic delays, and
            empower travelers to pursue their aspirations without undue
            administrative constraints. Our mission is to make visa processes as
            seamless as possible, offering personalized solutions that cater to
            the unique needs of each traveler. We strive to reduce the stress
            and uncertainty that often accompany visa applications, allowing our
            clients to focus on what truly matters—their travel plans and
            experiences. By continuously improving our processes and adopting
            new technologies, we aim to set new standards in visa facilitation
            services.
          </p>
        </div>

        {/* Image Section with sliding animation */}
        <div>
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/hotairbollon.webp")}
              alt="Hot Air Balloon"
              className="w-[300px] w-[300px] md:w-[560px] md:h-[490px]"
            />
          </Suspense>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center w-full sm:w-10/12 justify-between gap-10 py-10 md:py-0 md:mt-10 md:mt-[160px]">
        {/* Image Section */}
        <div className="w-full md:w-[553px] hidden md:block">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/Our Vision.webp")}
              alt="Mountain Vision"
              className="md:w-[553px] md:h-[489px] object-cover"
            />
          </Suspense>
        </div>

        {/* Text Section */}
        <div className="w-full md:w-6/12 text-center md:text-left md:mt-6 md:mt-0">
          <h2 className=" font-['Albert_Sans'] font-extrabold text-[32px] md:text-[40px] mb-6 md:mb-6">
            OUR VISION
          </h2>
          <p className="font-['Albert_Sans'] px-5 md:px-0 font-medium text-[18px] md:text-[20px]">
            We envision a world where travel is not restricted by bureaucratic
            complexities, but facilitated by innovative, technology-driven
            solutions. At The Flying Panda, we strive to be the trusted partner
            for travelers worldwide, helping them realize their dreams with
            confidence and ease. We see a future where visa applications are no
            longer a burden but a streamlined, transparent process that empowers
            individuals to explore the world freely. Our vision is not just
            about simplifying paperwork but about creating opportunities for
            cultural exchange, personal growth, and global connectivity. We
            believe that travel has the power to transform lives, and we are
            committed to making that transformation accessible to as many people
            as possible.
          </p>
        </div>
        <div className="w-full flex justify-center  block md:hidden">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/Our Vision.webp")}
              alt="Mountain Vision"
              className="w-[300px] h-[300px] object-cover"
            />
          </Suspense>
        </div>
      </div>

      <div className="flex flex-col items-center w-10/12 py-10 md:mt-[110px]">
        {/* Header */}
        <h2 className="font-['Albert_Sans'] font-black md:font-extrabold text-[32px] md:text-[40px] mb-6 md:mb-6">
          OUR VALUES
        </h2>

        {/* Icons and Text Sections */}
        <div className="flex flex-col md:flex-row items-center justify-between w-full gap-10">
          {/* Customer-Centricity */}
          <div className="flex flex-col items-center text-center md:w-1/3">
            <Suspense fallback={<Shimmer />}>
              <img
                src={Coustomercentric}
                alt="Customer-Centricity Icon"
                className="w-[250px] h-[250px] mb-6"
              />
            </Suspense>
            <h3 className="font-['Albert_Sans'] font-extrabold mb-5 md:mb-8 text-[24px] md:text-[26px]">
              CUSTOMER-CENTRICITY
            </h3>
            <p className="font-['Albert_Sans'] px-0 font-base text-[18px] md:text-[20px]">
              We place travelers at the heart of everything we do, ensuring that
              each experience is personalized and stress-free. Our team is
              dedicated to understanding the unique needs of each client and
              providing solutions that exceed expectations. We believe that
              every journey is personal, and our services are designed to
              reflect that individuality.
            </p>
          </div>

          {/* Innovation */}
          <div className="flex flex-col items-center text-center md:w-1/3">
            <Suspense fallback={<Shimmer />}>
              <img
                src={InnovationIcon}
                alt="Innovation Icon"
                className="w-[250px] h-[250px] mb-6"
              />
            </Suspense>
            <h3 className="font-['Albert_Sans'] font-extrabold mb-5 md:mb-10 text-[24px] md:text-[26px]">
              INNOVATION
            </h3>
            <p className="font-['Albert_Sans'] px-0 font-base text-[18px] md:text-[20px]">
              By integrating advanced technologies, we constantly seek to
              improve our services and address evolving challenges in visa
              facilitation. Innovation is at the core of our operations, from
              developing new tools to enhance efficiency to rethinking the way
              visa services are delivered. We are committed to staying ahead of
              industry trends and leveraging technology to provide the best
              possible service.
            </p>
          </div>

          {/* Reliability */}
          <div className="flex flex-col items-center text-center md:w-1/3">
            <Suspense fallback={<Shimmer />}>
              <img
                src={require("../assets/Images/aboutus/Reliability Icon-01.webp")}
                alt="Reliability Icon"
                className="w-[250px] h-[250px] mb-6"
              />
            </Suspense>
            <h3 className="font-['Albert_Sans'] font-extrabold mb-5 md:mb-8 text-[24px] md:text-[26px]">
              RELIABILITY
            </h3>
            <p className="font-['Albert_Sans'] px-0 font-base text-[18px] md:text-[20px]">
              Our commitment is to provide dependable support, enabling
              travelers to overcome any barriers they may face on their journey.
              We understand that reliability is key when it comes to travel
              planning, and our clients trust us to deliver on our promises.
              Whether it's securing a last-minute appointment or providing
              accurate information, we are here to support our clients every
              step of the way.
            </p>
          </div>
        </div>
      </div>
      <section className="w-full sm:w-10/12 gap-10 py-10 md:py-0 md:mt-10 md:mt-[160px]">
        {/* Text Section */}
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-6/12 text-center md:text-left md:mt-24">
            <h2 className="font-['Albert_Sans'] font-extrabold text-[32px] md:text-[40px] mb-6">
              OUR COMMITMENT
            </h2>
            <p className="text-center md:text-left font-['Albert_Sans'] font-medium text-[18px] md:text-[20px] px-5 md:px-0  mb-4">
              At The Flying Panda, our commitment goes beyond merely
              facilitating visa applications. We are dedicated to being a
              supportive partner throughout the entire travel journey. From the
              moment a traveler decides to embark on their adventure, we are
              here to guide, assist, and simplify the process. Our team of
              experts is always ready to provide insights, answer questions, and
              ensure that each client feels confident and prepared. We take
              pride in our ability to adapt to the unique requirements of each
              traveler, making sure that our services are as flexible and
              accommodating as possible.
            </p>
          </div>

          {/* Image Section */}
          <div className="flex justify-center w-full md:w-[553px] md:block hidden">
            <Suspense fallback={<Shimmer />}>
              <img
                src={require("../assets/Images/aboutus/commitment.webp")}
                alt="Our Commitment"
                className="w-[300px] w-[300px] md:w-[553px] md:h-[489px] object-cover"
              />
            </Suspense>
          </div>
        </div>

        {/* Additional Text Section */}
        <div>
          <p className="text-center md:text-left font-['Albert_Sans'] font-medium text-[18px] md:text-[20px] mt-10 px-5 md:px-0">
            We believe that travel should be an exciting and enriching
            experience, not marred by the stress of administrative hurdles. Our
            mission, vision, and values are all aligned with this belief, and we
            work tirelessly to make this a reality for every traveler who
            chooses The Flying Panda. Whether it's a business trip, a family
            reunion, or a long-awaited vacation, we are here to ensure that the
            journey is smooth, enjoyable, and unforgettable.
          </p>
        </div>

        <div className="flex justify-center w-full md:w-[553px] block md:hidden pt-10">
            <Suspense fallback={<Shimmer />}>
              <img
                src={require("../assets/Images/aboutus/commitment.webp")}
                alt="Our Commitment"
                className="w-[300px] w-[300px] md:w-[553px] md:h-[489px] object-cover"
              />
            </Suspense>
          </div>
      </section>

      <div className="flex flex-col md:flex-row items-center w-full sm:w-10/12 justify-between gap-10 pb-10 md:py-0 mt-10 md:mt-[100px]">
        {/* Image Section */}
        <div className="w-full flex justify-center md:w-[553px] hidden md:block">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/pexels-wildlittlethingsphoto-697244.webp")}
              alt="Milestones & Achievements"
              className="w-[300px] h-[300px] md:w-[553px] md:h-[450px] object-cover"
            />
          </Suspense>
        </div>

        {/* Text Section */}
        <div className="w-full md:w-6/12 text-center md:text-left mt-6 md:mt-0">
          <h2 className="font-['Albert_Sans'] font-extrabold text-[32px] md:text-[40px] mb-6 leading-[1.2]">
            MILESTONES & ACHIEVEMENTS
          </h2>
          <p className="font-['Albert_Sans'] font-medium text-[18px] md:text-[20px] px-5 md:px-0">
            Since our inception, The Flying Panda has achieved key milestones
            that reflect our dedication to visa facilitation and travel
            services. Our growth is driven by our commitment to excellence,
            innovation, and a client-first approach.
          </p>
        </div>

        <div className="w-full flex justify-center  block md:hidden">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/pexels-wildlittlethingsphoto-697244.webp")}
              alt="Milestones & Achievements"
              className="w-[300px] h-[290px] object-cover"
            />
          </Suspense>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center w-full sm:w-10/12 justify-between gap-10 pt-10 md:pt-0 mt-10 md:mt-[100px]">
        {/* Text Section */}
        <div className="flex flex-col space-y-4 w-full md:w-11/12 text-center md:text-left">
          <h2 className="text-[32px] md:text-[40px] font-extrabold font-['Albert_Sans'] mb-6">
            TEAM GROWTH
          </h2>
          <p className="text-[18px] md:text-[20px] font-['Albert_Sans']  px-5 md:px-0">
            Our team has expanded to include over{" "}
            <strong className="font-bold">10 skilled members</strong> dedicated
            to delivering exceptional service. Each member brings unique skills
            and experiences, allowing us to effectively address diverse client
            needs and provide high-quality, personalized support for travelers.
          </p>
        </div>

        {/* Image Section */}
        <div className="flex justify-center w-full  mt-6 md:mt-0">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/teamgrowth.webp")}
              alt="Team Growth"
              className="h-[300px] w-[300px] md:w-[553px] md:h-[450px] object-cover"
            />
          </Suspense>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center w-full sm:w-10/12 justify-between gap-10 py-10 md:py-0 mt-10 md:mt-[100px]">
        {/* Image Section */}
        <div className="w-full md:w-[553px] h-auto md:h-[450px] hidden md:block">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/clientsassited.webp")}
              alt="Milestones & Achievements"
              className="w-full h-full object-cover"
            />
          </Suspense>
        </div>

        {/* Text Section */}
        <div className="w-full md:w-6/12 text-center md:text-left mt-6 md:mt-0">
          <h2 className="font-['Albert_Sans'] font-extrabold text-[32px] md:text-[40px] mb-6 leading-[1.2]">
            CLIENTS ASSISTED
          </h2>
          <p className="font-['Albert_Sans'] font-medium text-[18px] md:text-[20px] px-5 md:px-0">
            We have successfully supported over
            <strong className="font-bold"> 1,500 clients</strong> in navigating
            complex visa processes. Our personalized approach ensures that each
            client receives the tailored assistance they need, resulting in
            successful visa applications and fulfilled travel aspirations.
          </p>
        </div>

        <div className="w-full flex justify-center md:w-[553px] h-auto md:h-[450px] block md:hidden">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/clientsassited.webp")}
              alt="Milestones & Achievements"
              className="w-[300px] h-[300px] object-cover"
            />
          </Suspense>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center w-full sm:w-10/12 justify-between gap-10 py-10 md:py-0 md:mt-10 md:mt-[100px]">
        {/* Text Section */}
        <div className="flex flex-col space-y-4 w-full md:w-11/12 text-center md:text-left">
          <h2 className="font-['Albert_Sans'] font-extrabold text-[32px] md:text-[40px] mb-6 leading-[1.2]">
            VISA APPLICATIONS PROCESSED
          </h2>
          <p className="font-['Albert_Sans'] font-medium text-[18px] md:text-[20px] px-5 md:px-0">
            We have processed over{" "}
            <strong className="font-bold"> 2,000 visa applications </strong>
            across<strong className="font-bold"> 60+ consulates</strong>. This
            milestone highlights our comprehensive understanding of various
            consulate requirements and our expertise in managing complex visa
            processes efficiently.
          </p>
        </div>

        {/* Image Section */}
        <div className="w-full flex justify-center ">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/visa-application-form-laptop.webp")}
              alt="Visa Applications Processed"
              className="w-[300px] h-[300px] md:w-[553px] md:h-[450px] object-cover"
            />
          </Suspense>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center w-full sm:w-10/12 justify-between gap-10 py-10 md:py-0 mt-10 md:mt-[100px]">
        {/* Image Section */}
        <div className="w-full md:w-[553px] h-auto md:h-[450px] hidden md:block">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/hands-waving-flags-china-germany-india-south-africa-russia.webp")}
              alt="Diverse Nationalities Served"
              className="w-full h-full object-cover"
            />
          </Suspense>
        </div>

        {/* Text Section */}
        <div className="flex flex-col space-y-4 w-full md:w-6/12 text-center md:text-left">
          <h2 className="font-['Albert_Sans'] font-extrabold px-[20px] md:px-0 text-[28px] md:text-[40px] mb-6 leading-[1.2]">
            DIVERSE  NATIONALITIES SERVED
          </h2>
          <p className="font-['Albert_Sans'] font-medium text-[18px] md:text-[20px] px-5 md:px-0">
            We have assisted clients from over{" "}
            <strong className="font-bold"> 50 different nationalities,</strong>
            demonstrating our global perspective and inclusivity. We take pride
            in contributing to global mobility and cultural exchange through our
            services.
          </p>
        </div>

        <div className="w-full flex justify-center md:w-[553px] h-auto md:h-[450px] block md:hidden">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/hands-waving-flags-china-germany-india-south-africa-russia.webp")}
              alt="Diverse Nationalities Served"
              className="w-[300px] h-[300px] object-cover"
            />
          </Suspense>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center w-full sm:w-10/12 justify-between gap-10 py-10 md:py-0 md:mt-[100px]">
        {/* Text Section */}
        <div className="flex flex-col space-y-4 w-full md:w-11/12 text-center md:text-left">
          <h2 className="font-['Albert_Sans'] font-extrabold text-[32px] md:text-[40px] mb-6 leading-[1.2]">
            GLOBAL REACH
          </h2>
          <p className="font-['Albert_Sans'] font-medium text-[18px] md:text-[20px] px-5 md:px-0">
            Our services extend to{" "}
            <strong className="font-bold">8 countries,</strong> including the
            UK, US, Ireland, India, Turkey, UAE, Morocco, and Pakistan. Our
            international presence ensures efficient and effective visa support
            for clients across the globe.
          </p>
        </div>

        {/* Image Section */}
        <div className="w-full flex md:flex-row  justify-center ">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/pexels-shvetsa-4167545.webp")}
              alt="Global Reach"
              className="w-[300px] h-[300px] md:w-[553px] md:h-[450px] object-cover"
            />
          </Suspense>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center w-full sm:w-10/12 justify-between gap-10 py-10 md:py-0 md:mt-[100px]">
        {/* Image Section */}
        <div className="w-full md:w-[553px] h-auto md:h-[450px] hidden md:block">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/pexels-wildlittlethingsphoto-697244.webp")}
              alt="Client Satisfaction"
              className="w-full h-full object-cover"
            />
          </Suspense>
        </div>

        {/* Text Section */}
        <div className="w-full md:w-6/12 text-center md:text-left mt-6 md:mt-0">
          <h2 className="font-['Albert_Sans'] font-extrabold text-[36px] md:text-[40px] mb-6 leading-[1.2]">
            CLIENT SATISFACTION
          </h2>
          <p className="font-['Albert_Sans'] font-medium text-[18px] md:text-[20px] px-5 md:px-0">
            <strong className="font-bold">50 different nationalities,</strong>{" "}
            We have received over{" "}
            <strong className="font-bold">100 tokens of appreciation</strong>{" "}
            from satisfied clients, symbolizing the trust and gratitude they
            have for our services, along with maintaining a 4.9 rating on{" "}
            <strong className="font-bold">Trustpilot.</strong> We strive to
            create positive experiences that go beyond simply processing visas,
            building long-lasting relationships.
          </p>
          <p className="mt-6 text-black font-['Albert_Sans'] font-medium text-[18px] md:text-[20px] px-5 md:px-0">
            These milestones reflect our unwavering commitment to excellence,
            innovation, and the trust our clients place in us. We are determined
            to surpass these milestones as we expand our services and help even
            more travelers achieve their goals.
          </p>
        </div>
      </div>
      <div className="w-full flex justify-center md:w-[553px] h-auto md:h-[450px] block md:hidden">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/aboutus/pexels-wildlittlethingsphoto-697244.webp")}
              alt="Client Satisfaction"
              className="w-[300px] h-[300px] object-cover"
            />
          </Suspense>
        </div>
    </div>
  );
};

export default AboutUs;
