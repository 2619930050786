// src/components/CustomNavbar.js
import React, { Suspense } from "react";
import { Navbar } from "flowbite-react";
import { useNavigate, useLocation } from "react-router-dom";

const NavbarComponent = () => {
  (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "olh9546boc");
  const navigate = useNavigate();
  const location = useLocation();
  const scrollToSection = (id) => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    setTimeout(() => {
      const element = document.getElementById(id);
      const offset = 80; // Adjust based on your navbar's height
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    });
  };

  return (
    <div className="sticky top-0 z-20 rounded-none">
      <Navbar fluid rounded className="p-0 lg:px-20 rounded-none ">
        <Navbar.Brand href="https://theflyingpanda.io/">
        <Suspense fallback={<div className="">The Flying Panda</div>}>
          <img
            src={require("../Utlis/Flying Panda Logo/Flying Panda Logo.jpg")}
            className="mr-3 w-[150px]"
            alt="Flowbite React Logo"
          />
          </Suspense>
        </Navbar.Brand>
        <div className="md:flex md:order-2 md:hidden">
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse className="">
          <Navbar.Link
            href="#"
            onClick={() => navigate("/aboutus")}
            className="text-xl md:font-normal"
          >
            <div className="py-6">About Us</div>
          </Navbar.Link>
          {/* <Navbar.Link
            href="#"
            onClick={() => scrollToSection("about")}
            active
            className="text-xl md:font-normal"
          >
            Home
          </Navbar.Link> */}
          <Navbar.Link
            href="#"
            onClick={() => navigate("/services")}
            className="text-xl md:font-normal"
          >            
            <div className="py-6">Services</div>
          </Navbar.Link>
          <Navbar.Link
            href="#"
            onClick={() => navigate("/pricing")}
            className="text-xl md:font-normal"
          >
            <div className="py-6">Pricing</div>
          </Navbar.Link>
          <Navbar.Link
            href="#"
            onClick={() => navigate("/FAQScreen")}
            className="text-xl md:font-normal"
          >
            <div className="py-6">FAQs</div>
          </Navbar.Link>
          <Navbar.Link
            href="#"
            onClick={() => scrollToSection("contact")}
            className="text-xl md:font-normal bg-[#00c3aa] "
          >
            <div className="p-6 text-white font-medium hover:text-[#011e41]">Contact</div>
          </Navbar.Link>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
