import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; 
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Navbar from "./component/navbar";
import Footer from "./component/footer";
import Error from './screen/Error';
import Homepage from "./screen/Homepage";
// import FAQScreen from "./screen/FAQScreen";
import FAQPage from "./screen/FAQPage"
import TermsAndConditions from "./screen/TermsandCondition";
import AboutUs from './screen/AboutUs'
import Services from './screen/Services';
import Pricing from './screen/pricing';
import SaperatedSAQ from './screen/SaperatedFAQPage';
import PrivacyPolicy from './screen/PrivacyPolicy'
//const root = ReactDOM.createRoot(document.getElementById('root'));

const AppLayout = () => {
  return (
    <div className="app">
      <Navbar />
        <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      }, 
      {
        path:"/FAQScreen",
        element:<FAQPage />
      },
      {
        path:"/terms",
        element:<TermsAndConditions />
      } ,
      {
        path:"/aboutus",
        element:<AboutUs/>
      },
      {
        path:"/services",
        element:<Services/>
      },
      {
        path:"/pricing",
        element:<Pricing/>
      },
      {
        path:"/saperatedfaq",
        element:<SaperatedSAQ/>
      },
      {
        path:"/privacy-policy",
        element:<PrivacyPolicy/>
      }
    ],
    errorElement: <Error />,
  },
]);

ReactDOM.createRoot(document.getElementById('root'))
  .render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );



reportWebVitals();

