import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "landingPageQuery"), {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        message: formData.message,
        createddatetime: new Date(),
        actiontaken:"No"
      });
      console.log("Document successfully written!");
      console.log(formData);
    } catch (e) {
      console.log("Error adding document: ", e);
    }
    formData.name = "";
    formData.email="";
    formData.mobile="";
    formData.message ="";
    setIsSubmitted(true);
    setTimeout(() => {
      setIsSubmitted(false);
    }, 5000);
  };
  return (
    <div className="contact-section flex items-center justify-center my-6 bg-gradient-to-b from-[#D9D9D9] to-[#CECECE] min-h-screen">
      <div className="container ">
        <div className="flex flex-wrap items-center justify-center mx-4 p-4">
          <div className="w-full md:w-9/12 lg:w-6/12  lg:mb-0">
            <div className="flex items-center  h-full rounded ms-0">
              <div className="lg:px-0 lg:me-20 lg:rounded-e-3xl pt-6 md:pt-0">
                <h2 className="text-4xl md:text-5xl lg:text-[5rem] text-center md:text-left lg:leading-[1.5] text-[#011e41]">
                  We'd love to
                </h2>
                <h2 className="text-4xl md:text-5xl lg:text-[5rem] text-center md:text-left lg:leading-[1.5] font-bold mb-4 text-[#011e41]">
                  hear from you
                </h2>
                <p className="mb-12 lg:mb-4 text-[18px] md:text-xl text-center md:text-left lg:text-2xl text-[#011e41]">
                  We welcome your inquiries and look forward to connecting with
                  you
                </p>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-4/12 px-4">
            <div className="rounded">
              <h2 className="text-2xl text-center lg:text-4xl font-bold mb-4 text-[#011e41]">
                Let's talk
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <input
                    className="w-full border-inherit py-4 px-8 placeholder-[#737373] placeholder:lg:text-2xl placeholder:text-md"
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    className="w-full border-inherit py-4 px-8 placeholder-gray-500 placeholder:lg:text-2xl placeholder:text-md"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    className="w-full border-inherit py-4 px-8 placeholder-gray-500 placeholder:lg:text-2xl placeholder:text-md"
                    type="number"
                    name="mobile"
                    placeholder="Contact Number"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-8">
                  <textarea
                    className="w-full border-inherit py-4 px-8 placeholder-gray-500 placeholder:lg:text-2xl placeholder:text-md"
                    name="message"
                    rows="6"
                    placeholder="Type Your Message Here"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="flex flex-col  justify-center items-center">
                  <button
                    className="flex items-center h-15 bg-white text-[#011e41] text-lg lg:text-2xl py-3 font-semibold px-12"
                    type="submit"
                  >
                    SUBMIT
                  </button>

                  {isSubmitted ? (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded mt-3 text-sm w-full text-center">
                      Your message has been sent successfully!
                    </div>
                  ) : (
                    <div className="h-12"></div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
