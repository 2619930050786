import React, { useEffect, useState } from "react";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";

const testimonialsData = [
  {
    id: 1,
    name: "Neha Jain",
    image: "",
    title: "Best in class professional service",
    content: [
      "Saksham is a very genuine and kind person. You can easily count upon him for your visa requirements, he guided me through the entire visa process and made it hassle free for me from obtaining an appointment to application process and assisting with all the documents. Couldn’t be better, totally worth it.",
    ],
  },
  {
    id: 2,
    name: "Sandesh Baral",
    image:
      "https://user-images.trustpilot.com/670e8d9877647a727836dc74/73x73.png",
    title: "Amazing service!!",
    content: [
      "Amazing service!!! Saksham guide us throughout the process and make our work easier and more convinient.Again coming back in near future !!!",
    ],
  },
  {
    id: 3,
    name: "Sreenivasan A C",
    image: "",
    title: "Excellent People",
    content: [
      "From beginning to finish, the team was really helpful and did an excellent job. Would definitely recommend people with their Visa application service. Excellent and really helpful. Understood my query straight away and did what needs to be done. Really pleased with parasol and their service. Would recommend people.",
    ],
  },
  {
    id: 4,
    name: "Elena Petrov",
    image: "",
    title: "Fantastic European Travel Planning",
    content: [
      "Huge thanks for the prompt delivery and assembly! The team from Furco made it look effortless, even though the suite is anything but light. I'm absolutely thrilled with my 3-2-1 black vegan leather set- it's incredibly comfortable, and I love being able to put my feet up wherever I sit. The size is perfect for my space, and the added back support is an unexpected bonus. Couldn't be happier!",
    ],
  },
  {
    id: 5,
    name: "Sandeep Sharma",
    image: "",
    title: "Junior was an incredible guide",
    content: [
      "Junior was an incredible guide, making the trek fascinating with his deep knowledge of Inca culture. The porters and chef were amazing, and the meals were delicious. We also customized our itinerary with Bambo to include Lake Titicaca and Huacachina, and every detail was perfectly organized.",
    ],
  },
  {
    id: 6,
    name: "SheilaThomas",
    image: "",
    title: "Hassle-Free Schengen Visa",
    content: [
      "The Flying Panda took all the hassle out of getting a Schengen visa. They were extremely organized and efficient in securing my appointment and preparing the necessary documents. I couldn’t believe how easy they made it. I will definitely use them for future trips.",
    ],
  },
  {
    id: 7,
    name: "Arijeet Diengdoh",
    image:
      "https://user-images.trustpilot.com/6647f46af9916e8ecfe89ac3/73x73.png",
    title: "Saksham was amazing with his knowledge…",
    content: [
      "Saksham was amazing with his knowledge of the visa process. He had great suggestions and went above and beyond to help. Thank you",
    ],
  },
  {
    id: 8,
    name: "FrannyBurek Qur",
    image: "",
    title: "Incredible Service for Schengen Visa..",
    content: [
      "The Flying Panda made the daunting process of getting a Schengen visa incredibly easy! Their team is extremely knowledgeable and guided me through every step. They secured my appointment in record time, and their attention to detail in preparing my documentation was outstanding. Highly recommended!",
    ],
  },
  {
    id: 9,
    name: "Jaya Shivaprakash",
    image:
      "https://user-images.trustpilot.com/5f182a69ba697e4c10830c5e/73x73.png",
    title: "Quick response and helpful",
    content: [
      "Saksham was very helpful. Replied quickly to my enquiry and also very quickly may be within few days secured a visa appointment for me. I was struggling for weeks to secure a slot and with no luck searched for someone to help me out and found 'The Flying Panda'. I am so grateful for all the help. Thanks you!",
    ],
  },
  {
    id: 10,
    name: "Ossai henry nwachukwu",
    image: "",
    title: "Trusted Team …",
    content: [
      "The Flying Panda took all the hassle out of getting a Schengen visa. They were extremely organized and efficient in securing my appointment and preparing the necessary documents. I couldn’t believe how easy they made it. I will definitely use them for future trips.",
    ],
  },
  {
    id: 11,
    name: "Priyanka Borole",
    image: "",
    title: "Schengen Visa Autobooking Experience",
    content: [
      "I had an amazing experience with FlyingPanda, Saksham was able to auto book the schengen visa appointment for us in just a few days after we finalised on a plan. Amazing service and he knows in and out regarding Schengen Visas for multiple countries and was patient enough to answer all my questions. Highly recommend this service if you need help.",
    ],
  },
  {
    id: 12,
    name: "Henry",
    image: "",
    title: "Speedy Schengen Visa Appointment",
    content: [
      "I was surprised at how quickly The Flying Panda secured my Schengen visa appointment. Their team is super efficient and knows the visa process inside out. They also helped me prepare all the documents, making the whole experience stress-free. Highly recommended!",
    ],
  },
  {
    id: 13,
    name: "Rufhdg Furhf",
    image: "",
    title: "Effortless US Visa Application",
    content: [
      "I was nervous about applying for a US visa, but The Flying Panda’s team made it a breeze. They were thorough with the documentation and had in-depth knowledge about the entire process. I got my appointment quickly and received my visa without any issues. Truly a lifesaver!",
    ],
  },
  {
    id: 14,
    name: "Kunal Jain",
    image: "",
    title: "Terrific service by Saksham & Flying Pands",
    content: [
      "We approached Saksham and Flying Panda Team for assistance for our entire family in getting Schengen Visa. Write from start his advice and instructions were very clear. Very helpful. He got us prompt appointments for France visa. Really made it tension free and smooth. Strongly recommend.",
    ],
  },
  {
    id: 15,
    name: "Zenab Kaduji",
    image: "",
    title: "The Flying Panda's efficiency in…",
    content: [
      "The Flying Panda's efficiency in getting my Schengen visa appointment was impressive. Their knowledge of the visa process and attention to detail with the documentation made the entire experience smooth and stress-free. I'm so glad I chose them for my visa needs!",
    ],
  },
  {
    id: 16,
    name: "JacquelineRahdar",
    image: "",
    title: "Exceptional Documentation Support",
    content: [
      "I’ve never had such a smooth visa application process. The Flying Panda’s team provided exceptional support with the documentation, ensuring everything was in order. They secured my Schengen visa appointment in no time, and I couldn’t be happier with their service.",
    ],
  },
  {
    id: 17,
    name: "Ritvik Kulkarni",
    image:
      "https://user-images.trustpilot.com/66fd96d1084cca585324a003/73x73.png",
    title: "Super recommend",
    content: [
      "The whole experience was brilliant, the knowledge of the whole process and will to help was genuine. And we managed to get two separate appointments, coincidentally on the same date.",
      "Highly recommended, hassle free experience.",
    ],
  },
  {
    id: 18,
    name: "Michael Larsen",
    image: "",
    title: "Expert US Visa Guidance",
    content: [
      "Applying for a US visa can be complicated, but The Flying Panda's expert guidance made it simple. They provided clear instructions and helped me with all the necessary paperwork. The process was smooth, and I got my visa without any delays. Excellent service!",
    ],
  },
  {
    id: 19,
    name: "onuh valentine",
    image:
      "https://user-images.trustpilot.com/66633332295412a80b4c3c91/73x73.png",
    title: "Amazing service",
    content: ["Amazing service, good customer relations and prompt service."],
  },
  {
    id: 20,
    name: "Shweta Koparde",
    image: "",
    title: "Exceptional Service for Hassle-Free Schengen Visa Appointments",
    content: [
      "I had been struggling for weeks to get my Schengen visa appointment and was about to give up on my travel plans. Then a friend recommended The Flying Panda to me. Upon contacting them, I received my visa appointment in less than a week. They are not only reliable but also supportive throughout the entire process. If you want a hassle-free visa appointment experience, The Flying Panda is your solution.",
    ],
  },
  {
    id: 21,
    name: "Shruti",
    image: "",
    title: "FlyingPandaTeam",
    content: [
      "I was struggling to get the visa appointment for france. Saksham from FlyingPanda team helped me and secure my visa appointment quick within a day.",
      "He was so friendly and also guided me with all my queries regarding application filling and itinerary planning.",
      "Thanks a lot. Really recommend Flying Panda team. Kudos keep up the good work 🙂",
    ],
  },
  {
    id: 22,
    name: "Swapnil Thorat",
    image: "",
    title: "France Visa Appointment within a week",
    content: [
      "We were struggling to find a Schengen visa appointment for France and had given up on our summer holiday plans.One of our friend recommended The Flying Panda services . They helped me in getting a visa appointment in less than a week.Thanks to Saksham who has been supportive throughout the entire process and guiding us with all our queries.",
      "I would highly recommend Flying Panda services to everyone who are sailing in the same boat.",
    ],
  },
  {
    id: 23,
    name: "Arzoo Singh",
    image:
      "https://user-images.trustpilot.com/66607410f1e71c7e85c6de2e/73x73.png",
    title: "Reliable and supportive",
    content: [
      "I had been struggling to get my Schengen visa appointment since weeks. I had almost given up on my travel plans but then, a friend recommended The Flying Panda to me. Upon contacting them, I received my visa appointment in less than a week. They’re not only reliable, they are also supportive throughout the whole process. They’re your solution to a hassle free visa appointment experience.",
    ],
  },
  {
    id: 24,
    name: "nikita tyagi",
    image: "",
    title: "It was a great experience",
    content: [
      "It was a great experience. I got his recommendation from one of my close friend. He could have charged me and made his business, instead he guided me properly as to how to navigate the VISA process and his information was precise and helped me to get through.",
      "It was a highly professionals and accurate guidance with good intentions to assist.",
    ],
  },
  {
    id: 25,
    name: "Pankaj Jain",
    image: "",
    title: "We have used their services to books visa appointments",
    content: [
      "We have used their services. They are honest and share correct details. They provided proper assistance during process and quick in replying to questions. We have got our bookings in timely manner. Definitely recommend them for great services.",
    ],
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + testimonialsData.length) % testimonialsData.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextTestimonial();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const currentTestimonial = testimonialsData[currentIndex];

  return (
    <div className="flex flex-col items-center text-center pt-10 md:pt-16 md:px-4">
      <h2 className="text-gray-400 uppercase tracking-wider text-[14px] md:text-xl mb-1">
        Testimonials
      </h2>
      <h2 className="text-[26px] md:text-[50px] pb-8 md:pb-0 px-4 md:px-0 font-bold text-[#011e41] mb-4 md:mb-14">
        WHAT OUR CUSTOMERS SAY ABOUT US
      </h2>

      <div className="md:flex lg:w-11/12 h-[600px] gap-5">
        {/* Background Image and Trustpilot Card */}
        <div className="relative mb-8 lg:w-6/12 hidden md:block">
          <img
            src={require("../assets/Images/aboutus/hotairbollon.webp")}
            alt="Hot air balloons"
            className="w-full h-full object-cover "
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="bg-white lg:w-6/12 p-6  shadow-md text-center">
              <p className="font-semibold text-[#011e41] mb-3">
                FOR MORE REVIEWS VISIT TRUSTPILOT
              </p>
              <a
                href="https://uk.trustpilot.com/review/theflyingpanda.io"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-black text-[20px] font-medium py-1 border-[3px] border-[#00c3aa] px-2"
              >
                Review us on{" "}
                <span className="text-[#00c3aa] text-[25px] ml-1">★</span>
                <span className=""> Trustpilot</span>
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start md:h-full lg:w-6/12 lg:ps-8 ">
          <div>
            {/* Testimonial Card */}
            <div className="bg-white text-center md:text-left rounded-lg md:h-80 px-6 md:px-0">
              <h3 className="font-bold text-[24px] md:text-[32px] text-[#011e41] md:mb-12 uppercase pb-8 md:pb-0">
                {currentTestimonial.name}
              </h3>
              <div className="text-[#FAB400] text-5xl mb-6 md:mb-4 ">★ ★ ★ ★ ★</div>
              <h4 className="font-bold text-[22px] md:text-[24px] text-[#011e41] mb-4">
                {currentTestimonial.title}
              </h4>
              <p className="text-[#011e41] h-[400px] text-[20px] md:text-[22px] ">
                {currentTestimonial.content}
              </p>
            </div>
          </div>
          {/* Navigation Arrows */}
          <div className="flex justify-center md:justify-start   md:ms-0 space-x-4 mt-24 md:mt-48">
            <button
              onClick={prevTestimonial}
              className="text-[#00c6ad] text-2xl border border-[#00c6ad] rounded-full p-2 hover:bg-[#00c6ad] hover:text-white transition-colors"
            >
              <FiArrowLeft />
            </button>
            <button
              onClick={nextTestimonial}
              className="text-[#00c6ad] text-2xl border border-[#00c6ad] rounded-full p-2 hover:bg-[#00c6ad] hover:text-white transition-colors"
            >
              <FiArrowRight />
            </button>
          </div>
        </div>
        <div className="relative mt-6 mb-8 lg:w-6/12  md:hidden block">
          <img
            src={require("../assets/Images/aboutus/hotairbollon.webp")}
            alt="Hot air balloons"
            className="w-full h-[325px] object-cover "
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="bg-white lg:w-6/12 p-6  shadow-md text-center">
              <p className="font-semibold text-[#011e41] mb-3">
                FOR MORE REVIEWS VISIT TRUSTPILOT
              </p>
              <a
                href="https://uk.trustpilot.com/review/theflyingpanda.io"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-black text-[20px] font-medium py-1 border-[3px] border-[#00c3aa] px-2"
              >
                Review us on{" "}
                <span className="text-[#00c3aa] text-[25px] ml-1">★</span>
                <span className=""> Trustpilot</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
