import React from "react";

const SelectPopup = ({ isOpen, comment, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
      onClick={onClose} // Close modal when clicking outside the content
    >
      <div
        className="bg-white lg-3/12 py-10 p-4 rounded-lg shadow-lg text-center"
        onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the box
      >
        <p className="text-gray-800 mb-4">{comment}</p>
        <button onClick={onClose} className="bg-[#00c3aa] p-2 rounded-md mt-2">Close</button>
      </div>
    </div>
  );
};

export default SelectPopup;
