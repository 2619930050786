import React from "react";
import { useNavigate } from "react-router-dom";

const VisaCard = ({ title, details, image }) => {
  const navigate = useNavigate();

  const handleSeeAll = () => {
    navigate("/saperatedfaq", { state: { selectedCategory: title } });
  };

  return (
    <div className="relative flex flex-col justify-between border-4 hover:border-0 border-[#00c3aa] rounded-lg p-4 h-80 group">
      <div className="absolute inset-0 bg-[#00c3aa] opacity-0 group-hover:opacity-10 transition-opacity rounded-lg"></div>

      <div className="relative z-10">
        <div className="w-full rounded-t-md">
          <img src={image} alt={title} className="w-10 h-10 ms-6" />
        </div>

        <div className="mt-4 px-5">
          <h2 className="text-2xl font-semibold text-[#011e41] mb-4 mt-5">
            {title}
          </h2>
          <ul>
            {Object.values(details).map((detail, index) => (
              <li key={index} className="text-sm text-left mb-2.5">
                {detail}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <button
        onClick={handleSeeAll}
        className="text-left relative z-10 block text-[#00c3aa] font-semibold hover:underline px-5"
      >
        See All
      </button>
    </div>
  );
};

export default VisaCard;
