import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="text-[#011e41] bg-white py-10 px-6 font-['Albert_Sans']">
      <header className="text-center">
        <h1 className="text-4xl font-bold text-gray-800">The Flying Panda</h1>
        <h2 className="text-2xl font-semibold text-gray-600 mt-2">
          Terms of Service
        </h2>
      </header>


      <div className="mt-10 w-11/12 mx-auto p-8 rounded-lg space-y-4">
        <section>
          <h3 className="text-xl font-semibold  text-[#00c6ad]">1. TERMS AND DEFINITIONS</h3>
          <p className="mt-4">
            1.1. To avoid any ambiguity and misunderstandings in interpreting
            this Agreement, the Parties have agreed that the following terms
            shall have the meanings assigned to them herein:
          </p>
          <p className="mt-2">
            1.1.1. <strong>"Agreement"</strong> − Public Offer Contract for the
            Provision of Information Services (hereinafter referred to as the
            “Agreement” or “Offer”).
          </p>
          <p>
            1.1.2. <strong>"Acceptance of the Agreement"</strong> − The
            customer's unequivocal and unreserved consent to the terms of this
            Agreement, manifested through the actions specified herein.
          </p>
          <p>
            1.1.3. <strong>"Software"</strong> − The website{" "}
            <a
              href="https://www.theflyingpanda.io"
              className="text-blue-600 underline"
            >
              https://www.theflyingpanda.io
            </a>
            , which hosts the text of this Agreement and a system for managing
            the Customer’s data, essential for the proper provision of Services.
          </p>
          <p>
            1.1.4. <strong>"Service Provider"</strong> − The entity known as The
            Flying Panda.
          </p>
          <p>
            1.1.5. <strong>"Customer"</strong> − Any individual who has accepted
            the Agreement and consented to its terms.
          </p>
          <p>
            1.1.6. <strong>"Services"</strong> − Services provided by the
            Service Provider to check for available appointment slots at Visa
            Application Centers (VAC) and possibly facilitate automated booking
            at a VAC, as detailed in the Software and this Agreement, or the
            rendering of Visa Facilitation Service.
          </p>
          <p>
            1.1.7. <strong>"Priority Booking Service"</strong> − An expedited
            service described in section 1.1.6, provided at a higher cost
            according to the Service Provider’s price list.
          </p>
          <p>
            1.1.8. <strong>"Service Order"</strong> − Actions by the Customer to
            arrange for Services using the Service Provider's Software,
            involving the submission of data necessary for the provision of the
            Service.
          </p>
          <p>
            1.1.9. <strong>"Necessary Data"</strong> − Information entered by
            the Customer for the Service Order, including but not limited to:
            surname, name, patronymic (if any), residence address (permanent and
            temporary), phone number, email address, IP address, marital status,
            photograph, identification number, network identifiers, date of
            birth, sex, citizenship, passport details, visa application data,
            and any other data disclosed during the conclusion and execution of
            the Agreement.
          </p>
          <p>
            1.1.10. <strong>"Price List"</strong> − Information regarding the
            cost of the Service Provider's Services, as presented in the
            Software/Website.
          </p>
          <p>
            1.1.11. <strong>"Appointment Fee"</strong> − An additional fee
            charged by VAC for processing applications, checking documents, and
            forwarding them to consulates/embassies.
          </p>
          <p>
            1.1.12. <strong>"Personal Account"</strong> − A personal page on the
            Service Provider’s Website.
          </p>
        </section>


        <section>
          <h3 className="text-xl font-semibold  text-[#00c6ad]">
            2. GENERAL PROVISIONS, ACCEPTANCE AND WITHDRAWAL OF ACCEPTANCE
          </h3>
          <p className="mt-4">
            2.1. This Agreement constitutes a public offer. Upon acceptance of
            the terms herein, the accepting individual becomes the Customer.
          </p>
          <p>
            2.2. The Service Provider and the Customer mutually guarantee their
            legal and operational capacity necessary to conclude and execute the
            Agreement.
          </p>
          <p>
            2.3. The Agreement is concluded by the Customer’s acceptance,
            expressed through the following conclusive actions performed
            sequentially:
          </p>
          <ul className="list-disc ml-5 space-y-2">
            <li>Reviewing the text of this Agreement;</li>
            <li>Reviewing the text of the Policy;</li>
            <li>Giving consent to the processing of personal data;</li>
            <li>
              Entering/Submitting the Necessary Data for the Service Order.
            </li>
          </ul>
        </section>

        <section>
          <h3 className="text-xl font-semibold  text-[#00c6ad]">3. SUBJECT OF THE AGREEMENT</h3>
          <p className="mt-4">
            3.1. The Service Provider agrees to provide the Customer with
            services for automated booking at the VAC or End-to-End Visa
            Facilitation Service as mutually agreed by both parties and detailed
            in this Agreement. The Customer agrees to pay for these services.
          </p>
        </section>

        <section>
          <h3 className="text-xl font-semibold  text-[#00c6ad]">3. SUBJECT OF THE AGREEMENT</h3>
          <p className="mt-4">
            3.1. The Service Provider agrees to provide the Customer with
            services for automated booking at the VAC or End-to-End Visa
            Facilitation Service as mutually agreed by both parties and detailed
            in this Agreement. The Customer agrees to pay for these services.
          </p>
          <p className="mt-2">
            3.2. The service completion date is when the Service Result is
            provided to the Customer. The Service Result (hereinafter referred
            to as “Result”) is:
          </p>
          <ul className="list-disc ml-5 space-y-2">
            <li>
              3.2.1. For Automated Booking at the VAC: A screenshot copy of the
              booked appointment, transmitted to the Customer in a converted
              form.
            </li>
            <li>
              3.2.2. For End-to-End Visa Facilitation Service: A completed
              document set needed for the visa application to be submitted to
              VAC (including a screenshot of a successfully booked appointment
              at VAC, application form(s), cover letter, and any other documents
              required on a case-to-case basis as per the checklist published on
              our website). After complete payment for the services, the Service
              Provider sends the fully produced result to the Customer. Once the
              result is provided and paid for, the obligations under the
              Agreement are considered fulfilled.
            </li>
          </ul>
          <p>
            3.3. The Service Provider may engage third parties to provide
            services under the Agreement and is responsible for their actions.
          </p>
          <p>3.4. The service is considered improperly rendered if:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>
              3.4.1. For Automated Booking at the VAC: The Service Provider
              fails to notify the Customer of the appointment at the VAC within
              three working days from the appointment date. The Customer must
              provide a screenshot of the VAC appointment screen and attach the
              pdf/png/jpeg file of the VAC appointment sent to the Customer.
            </li>
            <li>
              3.4.2. For End-to-End Visa Facilitation Service: The Service
              Provider fails to share the completed document set needed for the
              visa application to be submitted to VAC 24 hours before the
              Appointment at VAC. The Customer must provide a screenshot of the
              VAC appointment screen and attach the pdf/png/jpeg file of the VAC
              appointment sent to the Customer.
            </li>
          </ul>
          <p>
            3.5. The Service Provider reserves the right to terminate the
            provision of Services without refund if the Customer violates
            established rules of conduct during service provision, such as:
          </p>
          <ul className="list-disc ml-5 space-y-2">
            <li>Distributing advertising messages and spam;</li>
            <li>Using profane language;</li>
            <li>
              Disseminating false information or other actions violating the
              laws of the Republic of India;
            </li>
            <li>Sending notifications on external resources.</li>
          </ul>
        </section>

        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">
            4. PROCEDURE FOR PROVIDING SERVICES
          </h3>
          <p className="mt-4">
            4.1. The Service Provider renders services to the Customer upon
            acceptance of the Agreement by providing the necessary data for the
            Service Order.
          </p>
          <p>4.2. The Service Order can be made:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>1') By the Customer using the Service Provider's Software;</li>
            <li>2') Through the Service Provider’s manager(s).</li>
          </ul>
          <p>4.3. The service can be provided to the Customer through:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>4.3.1. The Telegram or WhatsApp application;</li>
            <li>4.3.2. The Service Provider’s manager;</li>
            <li>4.3.3. The Service Provider’s Website.</li>
          </ul>
          <p>
            4.4. Priority booking services are provided as described in section
            4.2 according to the Price List.
          </p>
          <p>
            4.5. If the Customer raises no claims within one day after receiving
            the result sent to the email specified in section 8.2, the service
            is deemed accepted and properly provided, and the Agreement
            fulfilled.
          </p>
        </section>

        <section>
          <h3 className="text-xl font-semibold  text-[#00c6ad]">
            5. COST OF SERVICES AND PAYMENT PROCEDURE
          </h3>
          <p className="mt-4">
            5.1. The cost of services is determined based on the chosen mission
            country (application location) and departure country, as specified
            in the Software or communicated by the manager per section 4.3.
          </p>
          <p>
            5.2. The cost is fixed and does not depend on the exchange rate. VAC
            service fees, Visa Application Fees, Cost of Bookings, and anything
            not mentioned in the scope of service as per section 3.2 are
            excluded from the cost of services.
          </p>
          <ul className="list-disc ml-5 space-y-2">
            <li>
              5.2.1. The Service Fee is paid by the Customer, who transfers the
              amount to the Service Provider's account, and the Service Provider
              makes the payment to the VAC.
            </li>
          </ul>
          <p>5.3. Payment for services can be made by:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>A') Bank transfer to the Service Provider’s account;</li>
            <li>B') Bank transfer using bank cards online;</li>
            <li>C') Electronic payment systems.</li>
          </ul>
          <p>
            5.4. Payment by bank transfer is made according to specified
            details.
          </p>
          <p>
            5.5. Payments are accepted via VISA, MasterCard, American Express,
            and Maestro cards, in the currency of the issuing bank. No
            additional charges apply for card payments. The minimum transaction
            is 50 INR or the equivalent. Transactions in other currencies are at
            the issuing bank’s rate.
          </p>
          <p>
            5.6. Payment via electronic payment systems can be made in any
            supported currency.
          </p>
        </section>

        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">
            6. TERMINATION OF THE AGREEMENT, ITS CONSEQUENCES AND REFUNDS
          </h3>
          <p className="mt-4">
            6.1. Termination of the Agreement and Services is recognized by:
          </p>
          <ul className="list-disc ml-5 space-y-2">
            <li>
              The Customer’s failure to appear at the VAC after the Service has
              been properly rendered;
            </li>
            <li>Non-payment for the Service Fee when properly rendered;</li>
            <li>The Customer’s refusal to pay the Service Fee.</li>
          </ul>
          <p>
            6.2. If the Customer places an Order but later refuses the result,
            any previously paid Service Fee is non-refundable.
          </p>
          <p>
            6.3. A full refund is issued if the Service Provider inadequately
            renders the Service, confirmed by appropriate evidence per section
            3.4.
          </p>
          <p>
            6.4. Refunds are made in the original payment currency and method
            unless the Customer agrees otherwise, without bearing commission
            fees.
          </p>
          <p>
            6.5. Erroneous transfers to non-Service Provider accounts are
            non-refundable.
          </p>
          <p>
            6.6. If the Customer refuses to pay after receiving the result, the
            Service Provider may take legal action to recover the debt.
          </p>
          <p>
            6.7. If the Customer does not appear at the VAC after receiving the
            result, the Service Fee and cost are non-refundable. Re-registration
            at the VAC requires a new Service payment.
          </p>
        </section>

        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">
            7. RIGHTS AND OBLIGATIONS OF THE PARTIES
          </h3>
          <p className="mt-4">7.1. The Service Provider shall:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>7.1.1. Deliver the Services timely and appropriately.</li>
            <li>7.1.2. Send notifications related to the Services.</li>
            <li>
              7.1.3. Be responsible for storing and processing the Customer's
              data.
            </li>
            <li>
              7.1.4. Ensure confidentiality of personal and other Necessary
              Data, using them solely for service provision.
            </li>
          </ul>
          <p>7.2. The Service Provider may:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>
              7.2.1. Change service provision terms unilaterally by publishing
              changes in the system. New terms apply to newly concluded
              Agreements.
            </li>
            <li>
              7.2.2. Block the Customer’s account for rule breaches without
              refund.
            </li>
            <li>7.2.3. Engage third parties for Services.</li>
          </ul>
          <p>7.3. The Customer shall:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>7.3.1. Accept and pay for the Services.</li>
            <li>7.3.2. Provide accurate information for the Services.</li>
            <li>
              7.3.3. Not reproduce, copy, sell, or use provided information and
              materials, except for personal use without third-party access.
            </li>
            <li>
              7.3.4. Independently check all the information, details, and/or
              documents produced by the service provider as the result of the
              service, and raise any concerns/corrections within 24
              (twenty-four) hours of receiving the result, after 24 hours, it
              will be deemed that the customer has received results to their
              level of satisfaction.
            </li>
          </ul>
          <p>7.4. The Customer may:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>
              7.4.1. Receive Services of proper quality and within appropriate
              timeframes.
            </li>
            <li>7.4.2. Exercise other rights provided by this Agreement.</li>
          </ul>
        </section>

        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">8. LIABILITY OF THE PARTIES</h3>
          <p className="mt-4">
            8.1. For non-performance or improper performance of obligations, the
            Parties are liable under Indian law.
          </p>
          <p>
            8.2. For non-provision or improper provision of Services, the
            Customer must contact the Service Provider via email at{" "}
            <a
              href="mailto:TheFlyingPanda.io@gmail.com"
              className="text-blue-600 underline"
            >
              TheFlyingPanda.io@gmail.com
            </a>
            .
          </p>
          <p>
            8.3. In unilateral refusal by the Customer after prepayment, the
            Service Provider may:
          </p>
          <ul className="list-disc ml-5 space-y-2">
            <li>
              8.3.1. Not refund the Service Fee if the Service has been rendered
              but the Customer terminates the Agreement.
            </li>
            <li>
              8.3.2. Consider termination accepted if communicated in writing to
              the email in section 8.2. Termination is effective upon receipt
              and fulfillment of financial obligations.
            </li>
          </ul>
          <p>
            8.4. No refund is issued if the Customer does not utilize the Result
            due to non-Service Provider reasons.
          </p>
          <p>
            8.5. The Parties are not liable for non-performance due to force
            majeure, including natural disasters, fires, epidemics, social
            disturbances, and other unavoidable circumstances.
          </p>
          <p>
            8.6. Obligation deadlines are postponed for the duration of force
            majeure events.
          </p>
        </section>

        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">9. DISPUTE RESOLUTION</h3>
          <p className="mt-4">
            9.1. All claims regarding service quality, including refund claims,
            must be sent to the Service Provider via the email in section 8.2.
            The consideration period is 30 calendar days from receipt.
          </p>
          <p>
            9.2. The Parties agree to pre-litigation dispute resolution
            ('negotiations, correspondence'). If unresolved, disputes may be
            referred to the Department of Consumer Affairs of the Republic of
            India.
          </p>
        </section>


        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">10. CONFIDENTIALITY</h3>
          <p className="mt-4">
            10.1. The Parties must maintain confidentiality of obtained
            information, not disclosing it except as required by Indian law and
            for fulfilling Agreement obligations.
          </p>
          <p>
            10.2. If unauthorized access to the Personal Account is detected,
            the Customer must notify the Service Provider immediately.
          </p>
        </section>

        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">
            11. TERM OF THE AGREEMENT AND FINAL PROVISIONS
          </h3>
          <p className="mt-4">
            11.1. This Agreement becomes effective upon publication at{" "}
            <a
              href="https://theflyingpanda.io/terms"
              className="text-blue-600 underline"
            >
              https://theflyingpanda.io/terms
            </a>{" "}
            and remains in effect until revoked or modified.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
