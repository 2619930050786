import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { BsChevronUp } from "react-icons/bs";


const FAQSaperated = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const location = useLocation();
  const { selectedCategory } = location.state || {};

  // Data object embedded directly in the component
  console.log(selectedCategory);
  const FAQData = {
    "Schengen Visa": [
      {
        id: 1,
        question: "What services does The Flying Panda offer?",
        answer:
          "We specialize in helping you secure Schengen visa appointments at VFS, TLS, or BLS centers, whether you’re applying for a Schengen visa from the UK or elsewhere. Our services include appointment booking, document assistance, and visa consultation for Schengen and non-Schengen countries like the USA, Canada, and Morocco.",
      },
      {
        id: 2,
        question: "How do I book a Schengen visa appointment?",
        answer:
          "Booking a Schengen visa appointment with us is easy. Just provide your details, and we will monitor available slots at VFS, TLS, or BLS visa centers to secure the earliest appointment. Our auto-booking service handles everything, ensuring you get the first available slot.",
      },
      {
        id: 3,
        question: "What documents do I need for a Schengen visa application?",
        answer:
          "For your Schengen visa application, you’ll need your passport, travel insurance, proof of accommodation, flight itinerary, and proof of financial means along with other documents on case to case basis. We help ensure all documents meet the VFS, TLS, or BLS center’s requirements for a smooth process.",
      },
      {
        id: 4,
        question: "How long does it take to get a Schengen visa appointment?",
        answer:
          "The time it takes to secure a Schengen visa appointment depends on availability at your nearest VFS, TLS, or BLS center. We monitor for the earliest possible appointments and notify you immediately when one becomes available.",
      },
      {
        id: 5,
        question: "Can you help with other types of visas besides Schengen?",
        answer:
          "Yes, we provide assistance for other types of visas, including those for the USA, Canada, Australia, New Zealand and Morocco. Whether you need help with a VFS visa appointment or another visa application center, we offer full support for your travel needs.",
      },
      {
        id: 6,
        question: "How much do your Schengen visa services cost?",
        answer:
          "Our pricing varies depending on the service you choose. We offer competitive rates for Schengen visa appointment bookings at VFS, TLS, or BLS centers. You can find a detailed breakdown of our pricing on our website.",
      },
      {
        id: 7,
        question: "Can I track my Schengen visa appointment status?",
        answer:
          "Yes, once your Schengen visa appointment is booked, we provide you with confirmation details and instructions.",
      },
      {
        id: 8,
        question: "What happens if I miss my Schengen visa appointment?",
        answer:
          "If you miss your Schengen visa appointment, we can help you reschedule at your VFS, TLS, or BLS center. However, rescheduling depends on the availability of new slots, and some centers may charge a rescheduling fee.",
      },
      {
        id: 9,
        question: "Do you offer group visa bookings for Schengen visas?",
        answer:
          "Yes, we assist with group bookings for Schengen visas, coordinating appointments for families, friends, or business groups. We handle the entire process with VFS, TLS, or BLS, ensuring your group’s appointments are aligned.",
      },
      {
        id: 10,
        question:
          "What payment methods do you accept for Schengen visa services?",
        answer:
          "We accept credit cards, debit cards, PayPal, and other secure online payment methods for our Schengen visa appointment booking services at VFS, TLS, or BLS centers. Payment is required to confirm your booking.",
      },
      {
        id: 11,
        question: "What if my Schengen visa application is rejected?",
        answer:
          "If your Schengen visa is rejected, we offer consultation services to help you understand the reasons for denial and guide you through the reapplication process. While we cannot guarantee approval, we help strengthen your application for VFS, TLS, or BLS submissions.",
      },
      {
        id: 12,
        question:
          "Do you provide refunds if no Schengen visa appointment is secured?",
        answer:
          "Yes, if we are unable to secure a Schengen visa appointment at VFS, TLS, or BLS, we offer a partial or full refund based on the service package you chose.",
      },
      {
        id: 13,
        question: "How do I contact your support team?",
        answer:
          "You can reach our support team via email, phone, or WhatsApp. We’re available to help you with any queries related to your Schengen visa appointment, the application process, or bookings at VFS, TLS, or BLS centers.",
      },
      {
        id: 14,
        question:
          "Do I need travel insurance for my Schengen visa application?",
        answer:
          "Yes, travel insurance is mandatory for a Schengen visa application. The policy must cover at least €30,000 in medical expenses and be valid for all Schengen countries. We assist you in finding the right insurance for your visa application at VFS, TLS, or BLS.",
      },
      {
        id: 15,
        question: "Can I apply for a Schengen visa without confirmed bookings?",
        answer:
          "Most Schengen visa applications require confirmed bookings for flights and accommodation. We provide provisional bookings that meet VFS, TLS, and BLS center requirements without the need for non-refundable purchases.",
      },
      {
        id: 16,
        question:
          "Do I need to attend the Schengen visa appointment in person?",
        answer:
          "Yes, you must attend your Schengen visa appointment in person to submit biometrics. We book your appointment at VFS, TLS, or BLS centers and provide guidance on preparing for your visit.",
      },
      {
        id: 17,
        question:
          "What is the auto-booking feature for Schengen visa appointments?",
        answer:
          "Our auto-booking feature automates the process of securing a Schengen visa appointment. We continuously monitor available slots at VFS, TLS, and BLS centers and automatically book the earliest available appointment for you.",
      },
      {
        id: 18,
        question:
          "How do I get notified about available Schengen visa appointments?",
        answer:
          "We notify you instantly via WhatsApp when a Schengen visa appointment becomes available at VFS, TLS, or BLS. If you choose our auto-booking service, we’ll book the slot for you and send confirmation.",
      },
      {
        id: 19,
        question: "Can I reschedule my Schengen visa appointment?",
        answer:
          "Yes, rescheduling your Schengen visa appointment is possible, but it depends on the availability of new slots at VFS, TLS, or BLS centers. We assist you in finding the next available date.",
      },
      {
        id: 20,
        question: "Which countries are included in the Schengen Area?",
        answer:
          "The Schengen Area consists of 27 countries, including France, Germany, Italy, Spain, and Switzerland. With a valid Schengen visa, you can travel freely between these countries for up to 90 days within a 180-day period.",
      },
      {
        id: 21,
        question:
          "Which non-Schengen countries can I visit with a Schengen visa?",
        answer:
          "Several non-Schengen countries, like Albania, Bulgaria, and Romania, allow entry with a valid Schengen visa. Always check the specific entry requirements for each non-Schengen country before traveling.",
      },
      {
        id: 22,
        question:
          "Which European countries do not require a Schengen visa for entry?",
        answer:
          "Countries like the UK, Ireland, and Turkey do not require a Schengen visa for entry. However, each country has its own visa policies, so check the entry requirements before planning your visit.",
      },
      {
        id: 23,
        question: "What is the visa acceptance rate for Schengen countries?",
        answer:
          "Acceptance rates vary across Schengen countries. Countries like Lithuania and Finland tend to have higher acceptance rates, while Belgium may have stricter reviews. We guide you through the process to maximize your approval chances.",
      },
      {
        id: 24,
        question: "Do I need a visa for airport transit in the Schengen Area?",
        answer:
          "Yes, depending on your nationality, you may need a Schengen airport transit visa. We help determine if you require a visa for transiting through a VFS, TLS, or BLS visa center and guide you through the process.",
      },
      {
        id: 25,
        question: "Can I travel to multiple Schengen countries with one visa?",
        answer:
          "Yes, a single Schengen visa allows travel to all 27 Schengen countries. Once your visa is issued, you can move freely between these countries during the validity of your visa.",
      },
      {
        id: 26,
        question: "How soon should I apply for a Schengen visa?",
        answer:
          "It’s best to apply for a Schengen visa at least 20 to 30 days before your intended travel date. You can apply up to six months in advance. We help you find an appointment at VFS, TLS, or BLS centers as early as possible.",
      },
      {
        id: 27,
        question:
          "Can I apply for a Schengen visa from the UK if I am not a UK citizen?",
        answer:
          "Yes, non-UK citizens can apply for a Schengen visa from the UK if they hold valid UK residency. We assist with booking your visa appointment at VFS, TLS, or BLS centers based on your residence status.",
      },
      {
        id: 28,
        question: "What is a multiple-entry Schengen visa?",
        answer:
          "A multiple-entry Schengen visa allows you to enter and exit the Schengen Area multiple times during the visa’s validity. We help you apply for a multiple-entry visa through VFS, TLS, or BLS centers.",
      },
      {
        id: 29,
        question: "Can I extend my Schengen visa while in the Schengen Area?",
        answer:
          "Visa extensions are granted in exceptional cases, such as medical emergencies. We advise you on the process and assist with your visa extension application, although the decision is made by local immigration authorities.",
      },
      {
        id: 30,
        question: "What happens if my Schengen visa application is incomplete?",
        answer:
          "An incomplete Schengen visa application may result in delays or rejection. We help ensure that your application meets all requirements for submission at VFS, TLS, or BLS visa centers, reducing the risk of errors or omissions that could delay your visa approval.",
      },
      {
        id: 31,
        question: "Can you guarantee visa approval for Schengen visas?",
        answer:
          "While we assist in preparing a complete and strong application for your Schengen visa, the final decision is made by the embassy or consulate, and we cannot guarantee approval. However, our experience with VFS, TLS, and BLS visa appointments helps maximize your chances of success.",
      },
      {
        id: 32,
        question: "Can I apply for a Schengen visa without a job?",
        answer:
          "Yes, you can apply for a Schengen visa even if you are unemployed. You will need to provide proof of financial means, such as savings, or have a sponsor cover your travel expenses. We assist with the documentation required for submission at VFS, TLS, or BLS centers.",
      },
      {
        id: 33,
        question:
          "Do you offer visa appointments for non-EU nationals applying for Schengen visas?",
        answer:
          "Yes, we assist non-EU nationals in booking Schengen visa appointments. Whether you are applying from the UK or another country, we help secure your slot at VFS, TLS, or BLS centers and provide personalized visa consultation services.",
      },
      {
        id: 34,
        question: "How soon can I get a Schengen visa appointment?",
        answer:
          "Appointment availability for Schengen visas depends on demand at your local VFS, TLS, or BLS center. We monitor open slots and help you secure the earliest available appointment based on your travel dates.",
      },
      {
        id: 35,
        question: "Can I cancel my Schengen visa appointment after booking?",
        answer:
          "Yes, you can cancel your Schengen visa appointment, but cancellation policies vary depending on the VFS, TLS, or BLS center. We’ll guide you through the cancellation or rescheduling process and inform you of any applicable fees.",
      },
      {
        id: 36,
        question: "What happens if my Schengen visa is delayed?",
        answer:
          "If your Schengen visa is delayed, it may be due to document verification or high demand at VFS, TLS, or BLS centers. We stay in touch with the visa center to keep you updated on the status and can help expedite the process where possible.",
      },
      {
        id: 37,
        question: "Can I apply for a Schengen visa for multiple countries?",
        answer:
          "Yes, with a Schengen visa, you can visit multiple countries within the Schengen Area. We ensure that your visa application reflects your travel plans, whether you’re visiting a single country or multiple Schengen countries.",
      },
      {
        id: 38,
        question: "What is the Visa Information System (VIS)?",
        answer:
          "The Visa Information System (VIS) is a database used by Schengen countries to store biometric data and visa application histories. Your fingerprints and photo will be stored in VIS for 59 months, which helps Schengen authorities verify your identity during visa processing and at border controls.",
      },
      {
        id: 39,
        question: "How can I improve my chances of getting a Schengen visa?",
        answer:
          "To improve your chances of securing a Schengen visa, ensure your application is complete and accurate, including proof of travel purpose, financial stability, and strong ties to your home country. We provide personalized consultation to help you prepare the best possible application for VFS, TLS, or BLS submissions.",
      },
      {
        id: 40,
        question:
          "Can you assist with urgent or express Schengen visa appointments?",
        answer:
          "Yes, we monitor Schengen visa appointments for urgent or express bookings, depending on availability at VFS, TLS, or BLS centers. While we can’t guarantee immediate appointments, we prioritize finding the earliest possible slots for urgent cases.",
      },
      {
        id: 41,
        question:
          "Do you offer visa services for diplomatic or official passports?",
        answer:
          "Yes, we assist with Schengen visa appointments for diplomatic and official passport holders. The process may differ from regular visa applications, and we’ll guide you through any additional requirements for your submission at VFS, TLS, or BLS centers.",
      },
      {
        id: 42,
        question: "Can you help with visa renewals?",
        answer:
          "Yes, we provide assistance with Schengen visa renewals, especially for travellers with expiring multiple-entry visas. We help you navigate the renewal process and secure an appointment at VFS, TLS, or BLS before your current visa expires.",
      },
      {
        id: 43,
        question:
          "Can you help with creating a travel itinerary for my Schengen visa application?",
        answer:
          "Yes, we provide travel itineraries that meet the requirements of Schengen visa applications. Whether you need flight reservations, hotel bookings, or a full travel plan, we offer solutions that meet the standards of VFS, TLS, or BLS centres without needing to finalize non-refundable purchases.",
      },
      {
        id: 44,
        question:
          "What should I do if my Schengen visa appointment is cancelled by the visa center?",
        answer:
          "If your Schengen visa appointment is cancelled by VFS, TLS, or BLS, we will immediately notify you. We’ll also guide you on any actions needed before your new appointment.",
      },
      {
        id: 45,
        question: "Can I apply for more than one Schengen visa at a time?",
        answer:
          "No, you can only hold one valid Schengen visa at a time. If you are applying for a longer-term visa (such as for work or study), we can help you manage the application for both short-term and long-term visa options.",
      },
      {
        id: 46,
        question: "Can you help with visa applications for minors?",
        answer:
          "Yes, we assist with Schengen visa applications for minors, which may require additional documents such as birth certificates, parental consent, or custody papers. We ensure that all documents are in order and assist in booking the appointment at VFS, TLS, or BLS centers.",
      },
      {
        id: 47,
        question: "Do I need to provide biometric data for my Schengen visa?",
        answer:
          "Yes, most applicants over the age of 12 are required to provide biometric data (fingerprints and a photo) when applying for a Schengen visa. We assist you in booking your appointment at VFS, TLS, or BLS centers where you can submit your biometrics.",
      },
      {
        id: 48,
        question: "Can I apply for a Schengen visa for multiple-entry?",
        answer:
          "Yes, a multiple-entry Schengen visa allows you to enter and exit the Schengen Area multiple times during the visa’s validity. We help you prepare your application to meet the requirements of VFS, TLS, or BLS visa centers.",
      },
      {
        id: 49,
        question:
          "How will I receive my passport after my Schengen visa is processed?",
        answer:
          "Once your Schengen visa is processed, you can collect your passport from the VFS, TLS, or BLS center, or opt for courier delivery. We provide tracking details for passport collection or delivery after the visa is issued.",
      },
      {
        id: 50,
        question: "Can I use a Schengen visa to visit non-Schengen countries?",
        answer:
          "Yes, certain non-Schengen countries allow entry with a valid Schengen visa, including Albania, Bulgaria, and Romania. Always verify the specific entry requirements before traveling.",
      },
      {
        id: 51,
        question: "Can I apply for a Schengen visa while visiting the UK?",
        answer:
          "Yes, if you are a resident, you can apply for a Schengen visa from the UK. We assist in booking your visa appointment at VFS, TLS, or BLS centers across the UK.",
      },
      {
        id: 52,
        question: "How long is a Schengen visa valid?",
        answer:
          "A Schengen visa is generally valid for up to 90 days within a 180-day period. However, the exact validity depends on the visa issued by the embassy or consulate. We help you understand your visa’s validity and travel requirements.",
      },
      {
        id: 53,
        question:
          "Can you help with airport transit visas in the Schengen Area?",
        answer:
          "Yes, if you need an airport transit visa for traveling through Schengen countries, we assist with booking appointments at VFS, TLS, or BLS centers and ensure you meet all visa requirements.",
      },
      {
        id: 54,
        question: "What happens if my Schengen visa application is delayed?",
        answer:
          "If your Schengen visa application is delayed, it may be due to additional document verification or high demand at VFS, TLS, or BLS centers. We help monitor your application status and assist with follow-ups to minimize delays.",
      },
      {
        id: 55,
        question:
          "Do you offer support for multiple Schengen visa applications?",
        answer:
          "Yes, we support individuals who need to apply for multiple types of Schengen visas, such as tourist and business visas. We streamline the application process and assist with all required appointments at VFS, TLS, or BLS centers.",
      },
      {
        id: 56,
        question: "How do I know which Schengen country to apply through?",
        answer:
          "You should apply for your Schengen visa through the country where you will spend the most time. If you’re visiting multiple Schengen countries, apply through the country of your first entry. We help guide you in choosing the right VFS, TLS, or BLS center for your application.",
      },
    ],
    "US Visa": [
      {
        id: 1,
        question: "How can I apply for a US visa from the UK?",
        answer:
          "To apply for a US visa from the UK, you need to complete the DS-160 form, pay the visa fee, and schedule an appointment at the US Embassy or Consulate. We help guide you through each step, from form submission to booking your US visa appointment.",
      },
      {
        id: 2,
        question: "What types of US visas can I apply for from the UK?",
        answer:
          "There are several US visa types you can apply for from the UK, including tourist (B1/B2), student (F1), and work visas (H1B, L1). We assist with the application process and help you book your US visa appointment at the nearest US embassy or consulate in the UK.",
      },
      {
        id: 3,
        question: "How long does it take to get a US visa from the UK?",
        answer:
          "The processing time for a US visa from the UK can vary depending on the visa type and the appointment availability. On average, the process can take anywhere from 2 weeks to a few months. We help expedite the process by securing the earliest US visa appointment available.",
      },
      {
        id: 4,
        question:
          "What documents do I need for a US visa application from the UK?",
        answer:
          "For a US visa application from the UK, you’ll need a valid passport, a completed DS-160 form, visa fee receipt, and relevant supporting documents like travel itinerary, financial proof, and employment verification. We help ensure that all documents are in order before your US visa appointment.",
      },
      {
        id: 5,
        question: "Can I expedite my US visa application from the UK?",
        answer:
          "Yes, in certain cases, you can request an expedited US visa appointment from the UK for urgent travel needs, such as medical emergencies or business travel. We assist in checking eligibility and submitting an expedite request.",
      },
      {
        id: 6,
        question:
          "Can I apply for a US visa from the UK if I’m not a UK citizen?",
        answer:
          "Yes, non-UK citizens residing in the UK can apply for a US visa if they hold valid UK residency. We guide you through the process, ensuring you meet the requirements for booking your US visa appointment.",
      },
      {
        id: 7,
        question: "How do I schedule a US visa appointment in the UK?",
        answer:
          "To schedule a US visa appointment in the UK, you must first complete the DS-160 form and pay the visa application fee. We assist with scheduling your appointment at the US Embassy in London or the US Consulate in Belfast.",
      },
      {
        id: 8,
        question:
          "What is the visa interview process for a US visa from the UK?",
        answer:
          "During the US visa interview in the UK, you will be asked about your travel plans, financial situation, and ties to your home country. We provide tips and guidance to help you prepare for your US visa interview and increase your chances of approval.",
      },
      {
        id: 9,
        question: "What if my US visa is denied?",
        answer:
          "If your US visa is denied, we help you understand the reason for rejection and guide you on the next steps for reapplying or submitting additional documentation. Our experts help you strengthen your case for the next US visa appointment.",
      },
      {
        id: 10,
        question: "How much does it cost to apply for a US visa from the UK?",
        answer:
          "The visa fee for a US visa application from the UK varies depending on the visa type. The standard tourist visa (B1/B2) fee is $180. We assist in ensuring your payment is processed and help you book your US visa appointment.",
      },
    ],

    "UK Visa from India": [
      {
        id: 1,
        question: "How do I apply for a UK visa from India?",
        answer:
          "To apply for a UK visa from India, you need to complete the online application form, pay the visa fee, and schedule an appointment at the nearest UK visa application center in India. We assist in completing the process and booking your UK visa appointment at VFS centers in India.",
      },
      {
        id: 2,
        question: "What documents do I need for a UK visa from India?",
        answer:
          "For a UK visa application from India, you’ll need your passport, visa application form, financial documents, proof of accommodation, and travel itinerary. We help ensure your documents meet the UK visa requirements for submission at VFS centers in India.",
      },
      {
        id: 3,
        question: "How long does it take to get a UK visa from India?",
        answer:
          "The processing time for a UK visa from India depends on the visa type. Standard visitor visas usually take 15-20 days, while work or study visas can take longer. We help book your UK visa appointment at the earliest available date to minimize delays.",
      },
      {
        id: 4,
        question: "Can I expedite my UK visa application from India?",
        answer:
          "Yes, you can request a priority service for your UK visa application from India, which typically reduces processing time to 5 working days. We help you apply for priority services and ensure your UK visa appointment is scheduled as soon as possible.",
      },
      {
        id: 5,
        question: "What types of UK visas can I apply for from India?",
        answer:
          "You can apply for several UK visa types from India, including visitor visas, work visas, and student visas. We help you determine the right visa category and assist with completing your application and booking your UK visa appointment.",
      },
      {
        id: 6,
        question:
          "What is the visa interview process for a UK visa from India?",
        answer:
          "While most UK visa applications from India do not require an interview, certain cases, like work visas or complex applications, may require a personal interview. We assist with preparing for your UK visa interview if one is scheduled.",
      },
      {
        id: 7,
        question: "What is the cost of a UK visa from India?",
        answer:
          "The cost for a UK visa from India varies by visa type. A standard visitor visa fee is approximately £100, while work and study visas have different fee structures. We help you navigate the fees and book your UK visa appointment at a VFS center in India.",
      },
      {
        id: 8,
        question:
          "Can I apply for a UK visa from India without confirmed bookings?",
        answer:
          "Yes, you can apply for a UK visa from India without confirmed travel bookings. However, you need to provide a provisional itinerary and proof of financial stability. We guide you through the documentation process to ensure a successful UK visa application.",
      },
      {
        id: 9,
        question: "Can I work in the UK on a visitor visa?",
        answer:
          "No, a UK visitor visa from India does not allow you to work in the UK. If you plan to work, you must apply for a UK work visa. We assist with choosing the right visa category and booking your UK visa appointment.",
      },
      {
        id: 10,
        question:
          "How can I check the status of my UK visa application from India?",
        answer:
          "You can track the status of your UK visa application from India through the VFS online tracking system. We provide you with guidance on how to monitor your application and offer updates if there are any delays or additional requirements.",
      },
    ],

    "Australia Visa": [
      {
        id: 1,
        question: "How do I apply for an Australia visa?",
        answer:
          "To apply for an Australia visa, you need to submit an online application through the Department of Home Affairs portal. Depending on the visa type (tourist, work, or student), you may need to provide documents such as your passport, financial proof, and travel itinerary. We assist in navigating the process and ensuring your Australia visa application is completed correctly.",
      },
      {
        id: 2,
        question: "What are the types of visas for Australia?",
        answer:
          "The most common types of Australia visas include visitor visas (subclass 600), work visas, and student visas. We help you determine the best visa option for your travel needs and assist with the application process.",
      },
      {
        id: 3,
        question: "How long does it take to get an Australia visa?",
        answer:
          "The processing time for an Australia visa depends on the visa type. Visitor visas typically take 20 to 30 days, while work and student visas may take longer. We help expedite the process by ensuring your application is complete and submitted correctly.",
      },
      {
        id: 4,
        question:
          "What documents are required for an Australia visa application?",
        answer:
          "For an Australia visa, you’ll need a valid passport, completed application form, financial proof, travel itinerary, and health insurance (for certain visa types). We guide you through the documentation process to ensure your Australia visa application meets all requirements.",
      },
      {
        id: 5,
        question: "Can I work in Australia on a visitor visa?",
        answer:
          "No, a visitor visa for Australia does not allow you to work. If you plan to work, you must apply for a relevant work visa, such as the Temporary Skill Shortage (TSS) visa. We help you apply for the correct visa based on your travel purpose.",
      },
    ],
    "New Zealand Visa": [
      {
        id: 1,
        question: "How do I apply for a New Zealand visa?",
        answer:
          "To apply for a New Zealand visa, you can complete an online application via the Immigration New Zealand website. Depending on the visa type, such as visitor, work, or student visas, we assist you in gathering the necessary documents and submitting your New Zealand visa application.",
      },
      {
        id: 2,
        question: "What types of visas are available for New Zealand?",
        answer:
          "Common New Zealand visa types include visitor visas, work visas, and student visas. We help you determine the appropriate visa based on your travel plans and assist with the application process to ensure a successful New Zealand visa application.",
      },
      {
        id: 3,
        question: "How long does it take to process a New Zealand visa?",
        answer:
          "The processing time for a New Zealand visa depends on the type of visa. Visitor visas usually take 15 to 25 days, while work and student visas may take longer. We assist with expediting your application by ensuring all documents are submitted correctly.",
      },
      {
        id: 4,
        question: "What documents are required for a New Zealand visa?",
        answer:
          "For a New Zealand visa, you’ll need a valid passport, visa application form, financial documents, travel itinerary, and in some cases, a medical certificate. We guide you through the documentation process to ensure your New Zealand visa application is complete and accurate.",
      },
      {
        id: 5,
        question: "Can I apply for a work visa in New Zealand?",
        answer:
          "Yes, if you plan to work in New Zealand, you will need to apply for a New Zealand work visa. We assist with the application process, helping you gather the necessary documents and submit your work visa application to Immigration New Zealand.",
      },
    ],

    "Canada Visa": [
      {
        id: 1,
        question: "How can I apply for a Canada visa?",
        answer:
          "To apply for a Canada visa, you must submit an application through the Immigration, Refugees, and Citizenship Canada (IRCC) portal. Whether you're applying for a visitor, work, or student visa, we assist you in completing your Canada visa application and gathering the required documents.",
      },
      {
        id: 2,
        question: "What are the types of Canada visas available?",
        answer:
          "The most common types of Canada visas include visitor visas, work permits, and study permits. We guide you through the selection of the right visa based on your travel needs and assist with the Canada visa application process.",
      },
      {
        id: 3,
        question: "How long does it take to get a Canada visa?",
        answer:
          "The processing time for a Canada visa varies depending on the visa type and your country of residence. Visitor visas usually take 10-20 days, while work and study permits may take longer. We help expedite your Canada visa application by ensuring it is complete and accurate.",
      },
      {
        id: 4,
        question: "What documents do I need for a Canada visa application?",
        answer:
          "For a Canada visa, you’ll need a valid passport, completed application form, financial proof, travel itinerary, and in some cases, a letter of invitation. We ensure that all required documents are submitted to support your Canada visa application.",
      },
    ],
    "Visa Extensions": [
      {
        id: 1,
        question: "Can I extend my visa for Australia, New Zealand, or Canada?",
        answer:
          "Yes, you may be able to extend your visa depending on the visa type and country-specific rules. We help guide you through the process of applying for an extension for your Australia visa, New Zealand visa, or Canada visa, ensuring you meet all the necessary conditions.",
      },
    ],
  };

  const toggleAnswer = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  // Filter questions for the selected category
  const filteredQuestions = FAQData[selectedCategory] || [];

  return (
    <div>
      <div className="relative h-[20vh] bg-[#00c3aa] font-['Albert_Sans'] md:mb-20">
        <div className="absolute lg:left-[30vw] left-[20vw] lg:top-[12vh] top-[15vh] flex justify-center items-center text-base lg:text-3xl font-bold uppercase w-[60vw] h-[10vh] lg:w-[40vw] lg:h-[20vh] bg-white border-2 border-[#00c3aa]">
          <h1>{selectedCategory}</h1>
        </div>
      </div>
      <div className="min-h-screen py-10 pt-20">
        <div className="max-w-5xl mx-auto bg-white rounded-md p-6">
          {/* FAQ Questions */}
          {filteredQuestions.length > 0 ? (
            <div className="">
              {filteredQuestions.map((item, index) => (
                <div
                  key={item.id}
                  className={`pb-4 cursor-pointer  mb-4 ${
                        activeIndex === index ? "border-x-2 border-b-2 border-[#00c3aa] " : ""
                      }`}
                  onClick={() => toggleAnswer(index)}
                >
                  {/* Question */}
                  <div className={`flex justify-between items-center border-b-2 border-[#00c3aa] p-3 px-10 ${
                        activeIndex === index ? "bg-[#00c3aa] text-white " : ""
                      }`}>
                    <p className=" font-medium text-xl">
                      <span>{item.id}. </span>
                      {item.question}
                    </p>
                    <span
                      className={`text-[#011e41] font-bold transition-transform ${
                        activeIndex === index ? "rotate-180" : ""
                      }`}
                    >
                      <BsChevronUp />
                    </span>
                  </div>

                  {/* Answer */}
                  {activeIndex === index && (
                    <div className="mt-2 text-[#011e41] text-base px-10 py-5">
                      {item.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-600">
              No FAQs available for this category.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQSaperated;
