import React from "react";

const PolicyOnPersonalDataProcessing = () => {
  return (
    <div className="text-[#011e41] py-10 px-6 bg-white font-['Albert_Sans']">

      <header className="text-center">
        <h1 className="text-4xl font-bold">The Flying Panda</h1>
        <h2 className="text-2xl font-semibold mt-2">
          Policy on Personal Data Processing
        </h2>
      </header>

      <div className="mt-10 w-11/12 mx-auto  p-8 rounded-lg  space-y-4">
        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">1. Terms and Definitions</h3>
          <p className="mt-4">
            1.1. "Personal data" refers to any information pertaining to an
            identified or identifiable natural person (data subject). An
            identifiable person is one who can be identified, directly or
            indirectly, particularly by reference to an identification number or
            to one or more factors specific to the physical, physiological,
            mental, economic, cultural, or social identity of that person.
          </p>
          <p className="mt-2">
            1.2. "Processing of personal data" encompasses any operation or set
            of operations performed on personal data, whether or not by
            automated means, including collection, recording, organization,
            structuring, storage, adaptation or alteration, retrieval,
            consultation, use, disclosure by transmission, dissemination, or
            otherwise making available, alignment or combination, restriction,
            erasure, or destruction.
          </p>
          <p>
            1.3. "Operator" refers to The Flying Panda, which independently or
            jointly with others organizes the processing of personal data,
            determines the purposes of such processing, and the actions
            (operations) performed with personal data.
          </p>
          <p>
            1.4. "User" denotes a natural person who accesses the Software via
            the Internet and utilizes it to obtain services from the Service
            Provider.
          </p>
          <p>
            1.5. "Policy" refers to this document, formulated in accordance with
            the legislation of the Republic of India, which defines the basic
            principles and rules for the processing of personal data and
            measures for their protection.
          </p>
          <p>
            1.6. "Software" refers to the Website{" "}
            <a
              href="https://theflyingpanda.io"
              className="text-blue-600 underline"
            >
              https://theflyingpanda.io
            </a>{" "}
            and/or the Telegram Chats and/or the WhatsApp chats/channel, on
            which this Policy is posted, operating together and collectively
            forming a unified system for data accounting necessary for the
            proper provision of services to Users.
          </p>
        </section>


        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">2. General Provisions</h3>
          <p className="mt-4">
            2.1. This Policy is formulated in accordance with the Law of the
            Republic of India in the field of protection and processing of
            personal data and applies to all personal data that the Operator may
            receive from the data subject intending to enter into and/or being a
            party to a civil law contract, as well as from the data subject
            having relations with the Operator governed by civil law.
          </p>
          <p>2.2. This Policy applies to all information that the Operator may obtain about visitors to the site <a href="https://theflyingpanda.io" className="text-blue-600 underline">https://theflyingpanda.io</a> and/or the Telegram chat and/or the WhatsApp chat.</p>
          <p>2.3. The Operator ensures the protection of processed data from unauthorized access and disclosure, illegal use, or loss, in accordance with the requirements of the Law of the Republic of India.</p>
          <p>2.4. The use of Software services by the User implies his unconditional agreement with this Policy and the terms of data processing.</p>
          <p>2.5. In the event of disagreement with the terms of this Policy, the User must cease using the Software.</p>
          <p>2.6. The Operator processes only the Data it receives from the User when using the Software, with the User's consent, provided by the User’s conclusive actions in the Software.</p>
          <p>2.7. The Operator does not verify the accuracy of the Data provided by the User in the Software. The Operator assumes that the User provides accurate and sufficient Data and keeps this information up to date. The User bears all responsibility for the consequences of providing inaccurate and/or invalid Data.</p>
        </section>
        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">3. Processing of Personal Data</h3>
          <p className="mt-4">3.1. Data Acquisition.</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>3.1.1. All Data shall be obtained directly from the User. If User Data can only be obtained from a third party, the subject must be informed, or consent must be obtained.</li>
            <li>3.1.2. The Operator must inform the User about the purposes, anticipated sources, and methods of obtaining the Data, the nature of the Data to be obtained, the list of actions with the Data, the duration of the consent, and the procedure for its withdrawal, as well as the consequences of the User's refusal to provide written consent for their acquisition.</li>
            <li>3.1.3. Documents containing data are created by copying original documents and entering information into forms provided in the Software.</li>
          </ul>
          <p className="mt-4">3.2. Data Processing.</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>3.2.1. The Operator Processes Data:</li>
            <ul className="list-disc ml-5 space-y-2">
              <li>With the consent of the User;</li>
              <li>
                In cases where processing is necessary for the performance and
                fulfilment of functions, powers, and duties imposed by the
                legislation of the Republic of India;
              </li>
              <li>
                In cases where processing involves Data, access to which has
                been provided by the User or at their request (hereinafter - data made publicly available by the User's decision).
              </li>
            </ul>
          </ul>

          <p className="mt-4">3.3. Purposes of Data Processing:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>3.3.1. Fulfilment of obligations arising from civil-law relations;</li>
            <li>3.3.2. Processing incoming requests from the user to provide services related to the Operator's field of activity.</li>
          </ul>

          <p className="mt-4">3.4. Categories of Data Subjects:</p>
          <p className="ml-5">3.4.1. Data concerning the following Users are processed:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>Individuals who are in civil-law relations with the Operator and/or intend to enter into civil-law relations with him.</li>
          </ul>

          <p className="mt-4">3.5. Data Processed by the Operator:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>Data obtained in the course of civil-law relations and/or the intention of the user to be in civil-law relations with the Operator.</li>
          </ul>

          <p className="mt-4">3.6. Data Processing is carried out:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>Using automation tools;</li>
            <li>Without using automation tools.</li>
          </ul>

          <p className="mt-4">3.7. Data Storage:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>3.7.1. User Data may be acquired, undergo further processing, and be transferred to the Operator or his employees, both on paper and electronically.</li>
            <li>3.7.2. Data recorded on paper is stored in lockable cabinets or locked rooms with restricted access.</li>
            <li>3.7.3. User Data processed using automated means to execute contracts is stored in different folders.</li>
            <li>3.7.4. It is not permitted to store and place documents containing Data in open electronic directories.</li>
            <li>
              3.7.5. Storage of Data in a form that allows identification of the
              Data Subject is carried out no longer than the purposes of their
              processing require, and they must be destroyed upon achieving the
              purposes of processing or when it is no longer necessary to achieve
              those purposes.
            </li>
          </ul>

          <p className="mt-4">3.8. Data Destruction:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>3.8.1. Destruction of documents (carriers) containing Data is carried out by shredding (grinding). The use of a shredder is allowed for the destruction of paper documents.</li>
            <li>3.8.2. Data on electronic carriers are destroyed by erasing or formatting the carrier.</li>
            <li>3.8.3. The fact of Data destruction is documented by a destruction act.</li>
          </ul>

          <p className="mt-4">3.9. Data Transfer:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>3.9.1. The Operator transfers Data to third parties in the following cases:</li>
            <ul className="list-disc ml-5 space-y-2">
              <li>The User has expressed their consent to the transfer of Data to a specific person and/or to an indefinite circle of persons;</li>
              <li>Transfer is mandated by Indian or other applicable legislation within the established procedure.</li>
            </ul>
          </ul>

          <p className="mt-4">3.10. Third Parties to whom Data is Transferred:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>Persons to whom the transfer of Data is mandated by the legislation of the Republic of India;</li>
            <li>Other persons to whom Data may be transferred under the terms of Consent from the transferring party.</li>
          </ul>
        </section>

        <section>
          <h3 className="text-xl font-semibold  text-[#00c6ad]">4. Protection of Personal Data</h3>
          <p className="mt-4">
            4.1. Data protection is carried out in accordance with the requirements of the legislation of the Republic of India, as well as international acts regulating the processing, storage, protection, and transfer (including cross-border) of Data.
          </p>
          <p className="mt-2">
            4.2. The Operator has established a personal data protection system (PDPS), consisting of legal, organizational, and technical protection subsystems. The legal protection subsystem comprises a complex of legal, organizational-administrative, and regulatory documents that ensure the creation, functioning, and improvement of the PDPS. The organizational protection subsystem includes the organization of PDPS management structures, and information protection when working with employees, partners, and third parties. The technical protection subsystem includes a complex of technical, software, and software-hardware means that ensure Data protection.
          </p>
        </section>

        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">5. Rights and Responsibilities of the Parties</h3>
          <p className="mt-4">5.1. The User has the right to:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>5.1.1. Access their Data and the following information:</li>
            <ul className="list-disc ml-5 space-y-2">
              <li>Confirmation of the fact of Data processing by the Operator;</li>
              <li>Legal grounds and purposes of Data processing;</li>
              <li>Purposes and methods of Data processing used by the Operator;</li>
              <li>Names, patronymics, and addresses of the Operator, information about individuals who have access to the Data;</li>
              <li>Terms of Data processing, including storage periods;</li>
              <li>The name or surname, name, patronymic, and address of the person processing the Data on behalf of the Operator if the processing is or will be entrusted to such a person.</li>
            </ul>
            <li>5.1.2. Contact the Operator and send inquiries.</li>
            <li>5.1.3. Appeal against actions (inaction) of the Operator.</li>
            <li>5.1.4. Exercise other rights provided by this Policy.</li>
          </ul>

          <p className="mt-4">5.2. The User is obligated to:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>5.2.1. Familiarize themselves with the text of this Policy.</li>
            <li>5.2.2. Provide truthful Data about themselves.</li>
            <li>5.2.3. Fulfil other obligations provided by this Policy.</li>
            <li>5.2.4. If the User believes that their rights and interests have been violated by the Operator, before exercising the right provided by 5.1.3 of this Policy, the User is obligated to contact the Operator's support service for explanations and dispute resolution through a claim procedure.</li>
          </ul>

          <p className="mt-4">5.3. The Operator has the right to:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>5.3.1. Receive reliable Data from Users.</li>
            <li>5.3.2. Use Data for fulfilling obligations under contracts concluded with the User.</li>
            <li>5.3.3. Make changes to this Policy.</li>
            <li>5.3.4. Exercise other rights provided by this Policy.</li>
          </ul>

          <p className="mt-4">5.4. The Operator is obligated to:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>5.4.1. Provide information on Data processing when collecting Data.</li>
            <li>5.4.2. If Data was obtained not from the User, notify the User.</li>
            <li>5.4.3. If refusing to provide Data, explain to the User the consequences of refusal.</li>
            <li>5.4.4. Publish or otherwise ensure unrestricted access to this Policy.</li>
            <li>5.4.5. Take necessary legal, organizational, and technical measures to protect Data from unlawful access and dissemination.</li>
            <li>5.4.6. Respond to inquiries and appeals from Users, their representatives, and authorized bodies.</li>
          </ul>
        </section>
        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">6. Purposes of Data Processing</h3>
          <p className="mt-4">
            6.1. The Operator collects and stores only those Data necessary for
            providing services or executing contracts with the User, except in
            cases where legislation requires mandatory storage of Data for a
            specified period.
          </p>
          <p className="mt-4">6.2. User Data is processed for:</p>
          <ul className="list-disc ml-5 space-y-2">
            <li>Filling out forms on the website <a href="https://theflyingpanda.io" className="text-blue-600 underline">https://theflyingpanda.io</a> and/or Telegram Chats and/or the WhatsApp chats/channel;</li>
            <li>Booking slots at visa centers;</li>
            <li>Rendering Visa Facilitation Services.</li>
          </ul>

          <p className="mt-4">
            6.2.1. The Operator processes User Data for:
          </p>
          <ul className="list-disc ml-5 space-y-2">
            <li>Carrying out registration (authorization) in the Software, access to the Software, and any other related actions;</li>
            <li>Providing the User access to personalized resources of the Software;</li>
            <li>Establishing feedback with the User, including sending Notifications, and inquiries concerning service provision, processing requests and applications from the User, and providing effective customer and technical support in case of problems related to the use of the Software;</li>
            <li>Ensuring the operability and security of the Software to confirm actions taken by Users, prevent fraud, computer attacks, and other abuses, and investigate such cases.</li>
          </ul>
        </section>

        <section>
          <h3 className="text-xl font-semibold text-[#00c6ad]">7. Dispute Resolution</h3>
          <p className="mt-4">
            7.1. Before filing a lawsuit for disputes arising from relations
            between the User and the Operator, it is mandatory to submit a claim
            sent to the Operator's email:{" "}
            <a
              href="mailto:theflyingpanda.io@gmail.com"
              className="text-blue-600 underline"
            >
              theflyingpanda.io@gmail.com
            </a>
            . The Operator must inform the Claimant in writing of the results of
            the claim review within 90 (Ninety) calendar days from the date of
            receipt.
          </p>
          <p className="mt-4">
            7.2. If it is impossible to settle the dispute amicably, the parties
            have the right to choose to approach:
          </p>
          <ul className="list-disc ml-5 space-y-2">
            <li>The judicial body at the location of the Operator;</li>
            <li>Department of Consumer Affairs of the Republic of India.</li>
          </ul>
          <p className="mt-4">
            7.3. The legislation of the Republic of India applies to this
            Privacy Policy and the relations between the User and the Operator.
          </p>
          <p className="mt-4">
            7.4. This Policy is effective as of "May 29, 2024."
          </p>
        </section>

      </div>
    </div>
  );
};

export default PolicyOnPersonalDataProcessing;
