import React, { Suspense } from "react";
import { Shimmer } from "../component/Shimmer";
import ExpertIcon from "../assets/Images/Expert Icon-01.svg";
import PersonalizedIcon from "../assets/Images/Personalized-01.svg";
import WebsiteIcon from "../assets/Images/Website Icons-01.svg";
import SuccessIcon from "../assets/Images/Success-01.svg";
import TechIcon from "../assets/Images/Tech-01.svg";
import ReliabilityIcon from "../assets/Images/Reliability Icon-01.svg";

const Whychooseus = () => {
  return (
    <div>
      <div className="md:hidden block h-[500px]"></div>
      <section className="flex flex-col items-center md:w-10/12 pb-10 mt-[60px] mx-auto">
        {/* Header */}
        <h2 className="text-[#011e41] font-['Albert_Sans'] font-extrabold text-[26px] md:text-[50px] mb-8 md:mb-10">
          WHY CHOOSE US
        </h2>
        <p className="text-[#011e41] font-['Albert_Sans'] text-[20px] px-4 mt-5 mt:mt-9 text-center">
          Choosing The Flying Panda means selecting a partner dedicated to
          providing an expertly managed travel experience. We blend specialized
          knowledge, customer-centric service, and advanced technology to ensure
          seamless visa facilitation:
        </p>
        {/* Icons and Text Sections */}
        <div className="flex flex-col md:flex-row items-center justify-between w-full gap-10">
          <div className="flex flex-col items-center text-center w-full md:w-1/3">
            {/* <Suspense fallback={<Shimmer/>}> */}
            <img
              src={ExpertIcon}
              alt="Customer-Centricity Icon"
              className="w-[200px] h-[200px] "
            />
            {/* </Suspense>   */}
            <h3 className="text-[#011e41] font-['Albert_Sans'] font-bold text-[26px] lg:text-[40px] mb-3 md:mb-6">
              EXPERTISE
            </h3>
            <p className="text-[#011e41] font-['Albert_Sans'] text-[20px] px-4 mt-5 mt:mt-9 md:px-4">
              Our seasoned professionals are well-versed in visa regulations,
              offering tailored guidance to meet each client's unique needs,
              ensuring even complex cases are handled effectively.
            </p>
          </div>

          <div className="flex flex-col items-center text-center w-full md:w-1/3">
            {/* <Suspense fallback={<Shimmer/>}> */}
            <img
              src={PersonalizedIcon}
              alt="Innovation Icon"
              className="w-[200px] h-[200px]"
            />
            {/* </Suspense> */}
            <h3 className="text-[#011e41] font-['Albert_Sans'] font-bold text-[26px] lg:text-[40px] mb-3 md:mb-6">
              PERSONALIZED SERVICE
            </h3>
            <p className="text-[#011e41] font-['Albert_Sans'] text-[20px] px-4 mt-5 mt:mt-9 md:px-8">
              We deliver customized itineraries and consultations, reflecting
              the uniqueness of each traveler’s journey and ensuring an
              experience aligned with their preferences.
            </p>
          </div>

          <div className="flex flex-col items-center text-center w-full md:w-1/3">
            {/* <Suspense fallback={<Shimmer/>}> */}
            <img
              src={WebsiteIcon}
              alt="Reliability Icon"
              className="w-[200px] h-[200px]"
            />
            {/* </Suspense> */}
            <h3 className="text-[#011e41] font-['Albert_Sans'] font-bold text-[26px] lg:text-[40px] mb-3 md:mb-6">
              GLOBAL REACH
            </h3>
            <p className="text-[#011e41] font-['Albert_Sans'] text-[20px] px-4 mt-5 mt:mt-9 md:px-12 pb-8">
              Our expertise across various consulates ensures efficient
              assistance no matter where you are or where you’re headed.
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between w-full gap-10">
          <div className="flex flex-col items-center text-center w-full md:w-1/3 pt-4">
            {/* <Suspense fallback={<Shimmer/>}> */}
            <img
              src={SuccessIcon}
              alt="Customer-Centricity Icon"
              className="w-[200px] h-[200px]"
            />
            {/* </Suspense> */}
            <h3 className="text-[#011e41] font-['Albert_Sans'] font-bold text-[26px] lg:text-[40px]  mb-6">
              PROVEN SUCCESS
            </h3>
            <p className="text-[#011e41] font-['Albert_Sans'] text-[20px] px-4 mt-3 mt:mt-9 md:px-12">
              With thousands of successful visa applications and positive client
              feedback, our reputation for excellence speaks for itself.
            </p>
          </div>

          <div className="flex flex-col items-center text-center w-full md:w-1/3 md:mt-12">
            {/* <Suspense fallback={<Shimmer/>}> */}
            <img
              src={TechIcon}
              alt="Innovation Icon"
              className="w-[200px] h-[200px]"
            />
            {/* </Suspense> */}
            <h3 className="text-[#011e41] font-['Albert_Sans'] font-bold text-[26px] lg:text-[40px]  mb-6">
              TECHNOLOGICAL SOLUTIONS
            </h3>
            <p className="text-[#011e41] font-['Albert_Sans'] text-[20px] px-4  mt:mt-9 mt- md:px-8">
              We use advanced technology to streamline visa processes, reduce
              delays, and provide real-time updates, keeping clients informed
              every step of the way.
            </p>
          </div>

          <div className="flex flex-col items-center text-center w-full md:w-1/3 pt-5 md:pt-10">
            {/* <Suspense fallback={<Shimmer/>}> */}
            <img
              src={ReliabilityIcon}
              alt="Reliability Icon"
              className="w-[200px] h-[200px] "
            />
            {/* </Suspense> */}
            <h3 className="text-[#011e41] font-['Albert_Sans'] font-bold text-[26px] lg:text-[40px] pt-3 md:pt-6">
              RELIABLE SUPPORT
            </h3>
            <p className="text-[#011e41] font-['Albert_Sans'] text-[20px] px-4 text-center mt-5 mt:mt-9 md:px-6">
              From planning to completion, we provide unwavering support to
              address urgent visa needs or last-minute changes, ensuring a
              stress-free journey.
            </p>
          </div>
        </div>
        <p className="text-[#011e41] font-['Albert_Sans'] text-[20px] mt-4 text-center px-4 md:px-0 mb-8 mt-20 md:mt-16">
          The Flying Panda values your travel dreams and works tirelessly to
          make them a reality. Our mission is to facilitate your journey, making
          it as smooth and enjoyable as possible.
        </p>
      </section>
    </div>
  );
};

export default Whychooseus;
