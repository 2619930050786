import React from "react";
import PricingSection from "../sections/PricingSection";
import Visa1 from "../assets/Images/services/Documents-01.svg";
import VisaDocumentation from "../assets/Images/services/Consulting-01.svg";
import VisaConsultation from "../assets/Images/services/Itiniary-01.svg";
import TravelInsurance from "../assets/Images/services/Visa-01.svg";
import ItineraryReservations from "../assets/Images/services/Personalized Itiniary-01.svg";
import HotelTravelBookings from "../assets/Images/services/Hotel-01.svg";
import PersonalizedItineraries from "../assets/Images/services/Passport-01.svg";
import CommunityGroupTours from "../assets/Images/services/Group-01.svg";
import PassportServices from "../assets/Images/services/Passport-01.svg";
import SchengenVisaAppointment from "../assets/Images/services/Notification-01.svg";

const prices = [
  {
    id: 1,
    title: "Visa Documentation Services",
    text: "Our documentation services are generally priced at £50/€50 for most countries. Costs may vary based on specific documentation requirements or additional services requested. Please use our Contact Us form for a tailored quote. We assist in preparing and reviewing documents to meet consulate requirements.",
    image: Visa1,
  },
  {
    id: 2,
    title: "Visa Consultation",
    text: "We provide free basic visa consultations. For more complex cases, we offer detailed consultations at £75/hour, including document evaluations and strategies to address prior rejections or unique situations.",
    image: VisaDocumentation,
  },
  {
    id: 3,
    title: "Itinerary Reservations",
    text: "Flight and accommodation itinerary reservations are available for £50/€50 per person per trip. This service includes proof of travel for visa applications without requiring non-refundable payments.",
    image: VisaConsultation,
  },
  {
    id: 4,
    title: "Hotel and Travel Bookings",
    text: "We provide customized hotel and travel booking services, catering to different budgets and preferences. Contact us for personalized options and pricing.",
    image: HotelTravelBookings,
  },
  {
    id: 5,
    title: "Personalized Itineraries and Tours",
    text: "Customized travel planning services are available for £5 per person per day for groups of up to five people. For larger groups, flat rates are offered. Our itineraries include local insights and unique experiences to make your travel memorable.",
    image: ItineraryReservations,
  },
  {
    id: 6,
    title: "Community /Group Tours",
    text: "Group tour prices are published periodically on our website and social media channels. These tours offer shared travel experiences and competitive group rates.",
    image: CommunityGroupTours,
  },
  {
    id: 7,
    title: "Passport Services",
    text: "We provide passport services for £50 per person, covering renewals, re-issuances, and endorsements. Additional charges may apply for expedited services or special circumstances. Assistance includes expedited processing for urgent needs.",
    image: PersonalizedItineraries,
  },
  {
    id: 8,
    title: "Schengen Visa Appointment Notification Service",
    text: "Our free Schengen Visa Appointment Notification Service offers real-time alerts for available appointments via WhatsApp. Join our WhatsApp Group.",
    image: SchengenVisaAppointment,
  },
];

const pricing = () => {
  return (
    <div>
      <div>
        <div className="relative">
          <div className="lg:h-96">
            <img
              src={require("../assets/Images/services/pricing-main-img.webp")}
              alt="Mountain Image"
              className="w-8/12  h-32 lg:h-96 flex justify-end "
            ></img>
          </div>
          <div className="flex justify-end items-end">
            <div className="flex justify-center items-center absolute top-[60px] lg:top-[130px] text-center  w-7/12 bg-[#00c3aa] h-32 lg:h-96 font-bold text-white text-center font-['Albert_Sans']">
              <h1 className="text-4xl lg:text-8xl w-3/4 md:w-1/2 ">OUR PRICING</h1>
            </div>
          </div>
        </div>
        <p className="lg:w-full text-center px-5 lg:px-0  pt-20 lg:pt-40 pb-8 lg:pb-20 lg:pb-0 lg:p-20 text-base lg:text-2xl text-[#011e41] font-normal">
          At The Flying Panda, we offer transparent and flexible pricing to
          accommodate the diverse needs of travellers. Below is a summary of our
          pricing structure to help you understand our offerings in detail.
        </p>
        <h1 className="text-[28px] lg:text-5xl text-[#011e41]  font-bold text-center">
          VISA APPOINTMENT SERVICES
        </h1>
        <div className="flex flex-col justify-center items-center">
          <p className="hidden lg:block w-11/12 pt-10 text-center text-base lg:text-2xl text-[#011e41] font-normal">
            Our visa appointment pricing depends on the application country,
            citizenship, and destination, as these factors affect the complexity
            of the visa process, required documentation, and associated
            administrative costs.
          </p>
          <p className="mt-6 w-11/12 text-center text-base lg:text-2xl text-[#011e41] font-normal">Please select the appropriate options below to know our offerings-</p>
        </div>
      </div>
      <div className="mt-12 mb-20">
        <PricingSection />
      </div>
      <div className="flex flex-col items-center">
        {prices.map((price, index) => (
          <div
            key={price.id}
            className={`flex flex-col md:flex-row w-10/12  ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            } items-center md:pb-[50px] mt-12 md:mt-0`}
          >
            <div className="w-full md:w-3/12 flex justify-center ">
              <img
                src={price.image}
                alt={`${price.title} image`}
                className="w-8/12 md:w-11/12"
              />
            </div>
            <div className="w-full md:w-9/12">
              <h2 className="text-[26px] lg:text-[40px] leading-tight text-center lg:text-left uppercase font-bold text-[#011e41] mb-8">
                {price.title}
              </h2>
              <p className="text-base lg:text-2xl text-center lg:text-left text-[#011e41] font-normal leading-tight">
                {price.text}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="h-8"></div>
    </div>
  );
};

export default pricing;
{
  /* <img
          src={require("../assets/Images/services/pricing-main-img.jpg")}
          alt="Mountain Image"
          className="w-8/12 h-96 flex justify-end "
        ></img> */
}

// <h1 className="absolute h-96 text-[100px] font-bold text-white w-1/2 text-center font-['Albert_Sans'] ">
//   OUR SERVICES
// </h1>
