import React from "react";
import Visa1 from "../assets/Images/services/Visa-01.svg";
import VisaDocumentation from "../assets/Images/services/Documents-01.svg";
import VisaConsultation from "../assets/Images/services/Consulting-01.svg";
import TravelInsurance from "../assets/Images/services/Insurance-01.svg";
import ItineraryReservations from "../assets/Images/services/Itiniary-01.svg";
import HotelTravelBookings from "../assets/Images/services/Hotel-01.svg";
import PersonalizedItineraries from "../assets/Images/services/Personalized Itiniary-01.svg";
import CommunityGroupTours from "../assets/Images/services/Group-01.svg";
import PassportServices from "../assets/Images/services/Passport-01.svg";
import SchengenVisaAppointment from "../assets/Images/services/Notification-01.svg";

const services = [
  {
    id: 1,
    title: "Visa Appointments",
    text: "We specialize in securing visa appointments at embassies, consulates, and visa application centers, including VFS, TLS, and BLS, efficiently obtaining the earliest and most convenient slots for our clients. Leveraging our in-depth expertise in navigating these intricate systems using cutting-edge technology, we offer automated appointment booking, minimizing common obstacles and delays. We not only schedule appointments but also keep track of potential changes in consulate protocols, ensuring our clients are always up-to-date. We provide timely updates and reminders, guiding clients on the necessary documents and what to expect during their visa interview. Our proficiency in managing even the most challenging scheduling scenarios ensures a hassle-free experience, reducing the typical stress associated with visa appointments.",
    image: Visa1,
  },
  {
    id: 2,
    title: "Visa Documentation",
    text: "Visa documentation requirements can be highly complex, given the variations across different embassies, consulates, and visa centers. Our comprehensive service encompasses end-to-end support, from organizing and reviewing documents to ensuring full compliance with embassy standards. Our specialists meticulously guide clients through each document, guaranteeing accuracy, completeness, and adherence to specific visa requirements. We also provide customized document checklists, ensuring clients are well-prepared with all necessary paperwork. By reducing the risk of errors or omissions, we significantly enhance the success rate of visa applications, minimizing potential delays or rejections due to inadequate paperwork. We handle translations, certifications, and notarizations, ensuring every document meets stringent embassy requirements. With our assistance, clients experience a streamlined documentation process, often the most daunting part of a visa application.",
    image: VisaDocumentation,
  },
  {
    id: 3,
    title: "Visa Consultation",
    text: "Our visa consultation services provide tailored guidance, addressing the complexities of different visa categories, requirements, and optimal strategies for individual cases. We assess travel objectives to ensure clients apply for the most appropriate visa type, whether for tourism, work, study, or family reunification. Our visa processing service covers the entire procedure, from document collection and form submission to application tracking and follow-up communications. Our consultants provide personalized coaching for visa interviews, offering insights on potential questions and how to present the case effectively. We also manage follow-up communications and additional documentation requests from consulates, ensuring meticulous attention to every detail for a favorable outcome. Our in-depth knowledge of varying embassy expectations allows us to advocate effectively for our clients, mitigating the likelihood of complications. For complex cases involving previous visa rejections, we provide strategic solutions to improve the outcome of reapplications.",
    image: VisaConsultation,
  },
  {
    id: 4,
    title: "Travel Insurance",
    text: "Travel insurance is a critical aspect of international travel, offering protection against unforeseen circumstances such as medical emergencies, lost baggage, and trip disruptions. We specialize in providing mandatory travel insurance required for Schengen visas, ensuring clients meet all regulatory requirements seamlessly. Our partnerships with leading insurers enable us to offer a broad selection of insurance plans that cater to different travel needs, from single-trip coverage to multi-trip policies for frequent travelers. We provide competitive quotes and comprehensive coverage options, including medical evacuation, repatriation, and coverage for COVID-19-related disruptions. Our team assists clients in selecting the optimal insurance policy based on destination, trip duration, and specific travel needs while helping them understand policy terms for complete clarity. We take the guesswork out of selecting travel insurance, ensuring our clients are fully protected no matter where their journey takes them.",
    image: TravelInsurance,
  },
  {
    id: 5,
    title: "Itinerary Reservations",
    text: "We provide original flight and accommodation reservations necessary for visa applications, particularly advantageous for Schengen visas that require confirmed travel plans. Our service enables clients to obtain genuine reservation confirmations without making non-refundable commitments until visa approval. This flexibility protects clients' financial interests while meeting visa requirements. Unlike dummy bookings, which can be unreliable and lead to visa rejections, our reservations are verifiable, providing a secure and trustworthy option. We collaborate with airlines and hotels to align reservations with clients' travel schedules, ensuring that all travel plans are cohesive and practical. Additionally, our ongoing collaboration with travel partners means we can make adjustments quickly, should the visa process require modifications to the itinerary. This service gives clients peace of mind, knowing they have a solid, adaptable travel plan in place without risking upfront payments.",
    image: ItineraryReservations,
  },
  {
    id: 6,
    title: "Hotel & Travel Bookings",
    text: "Whether planning a business trip or a leisurely vacation, our hotel and travel booking services cater to diverse traveler needs. Through our partnerships with leading travel providers, we offer competitive rates and exclusive deals not available to the general public. We take the time to understand client preferences—whether they seek budget-friendly options, boutique accommodations, or luxurious experiences—and tailor our recommendations accordingly. We manage all aspects of travel logistics, including hotel bookings, flights, airport transfers, and curated local experiences, ensuring clients enjoy a comfortable, well-organized, and memorable journey. Our concierge-level services include booking exclusive activities, dining reservations, and unique local experiences that turn an ordinary trip into an extraordinary adventure. By handling all logistics, we free our clients to focus on the joy of travel, knowing every detail is expertly managed.",
    image: HotelTravelBookings,
  },
  {
    id: 7,
    title: "Personalized Itineraries and Tours",
    text: "Our personalized itinerary and tour services are designed to provide clients with enriching and unique travel experiences. We create customized itineraries that reflect each client's interests—whether cultural immersion, historical exploration, adventure, or relaxation. From arranging local tours to identifying key attractions and hidden gems, we ensure every trip is filled with memorable experiences. Our knowledgeable local guides offer insightful perspectives on each destination, enhancing the travel experience through authentic local engagement. We also provide detailed recommendations for dining, entertainment, and activities, ensuring each journey matches the client's personality and preferences. For clients seeking off-the-beaten-path experiences, we curate specialized tours that go beyond typical tourist attractions, offering a deeper and more immersive experience. Our goal is to deliver meticulously curated, unforgettable journeys that leave clients with lasting memories and a deeper appreciation of their destination.",
    image: PersonalizedItineraries,
  },
  {
    id: 8,
    title: "Community/Group Tours",
    text: "For travelers who appreciate shared experiences, our community and group tours offer the perfect blend of adventure, culture, and camaraderie. These tours are thoughtfully curated, covering all aspects of group travel, including accommodation, transportation, and guided activities. We aim to foster a sense of community by providing participants with opportunities to build meaningful connections while exploring new destinations. Our group tours are designed to cater to diverse interests, whether you are an adventure enthusiast, a history buff, or simply enjoy social travel experiences. By emphasizing comfort, adventure, and cultural enrichment, we ensure that group travelers have a rewarding and enjoyable experience. Our experienced tour leaders facilitate group dynamics, ensuring everyone feels included and engaged throughout the journey. Whether it's a small intimate group or a larger gathering, our community tours are designed to create memories that travelers will cherish for years to come.",
    image: CommunityGroupTours,
  },
  {
    id: 9,
    title: "Passport Services",
    text: "In addition to our visa services, we provide comprehensive passport assistance, covering renewals, new applications, and lost passport situations. Our team guides clients through each step, ensuring all necessary documentation is gathered and all forms are accurately completed. We provide advice on expedited services for urgent travel needs and assist in navigating any challenges that may arise during the passport application process. We strive to simplify and expedite the process, reducing the stress typically associated with passport applications, whether for a first-time application or a renewal. Our passport services also extend to providing information on visa-free travel destinations, helping clients maximize the potential of their passports. By offering end-to-end support, we ensure that our clients have all the necessary documentation to travel without concerns, allowing them to focus on enjoying their upcoming journey.",
    image: PassportServices,
  },
  {
    id: 10,
    title: "Schengen Visa Appointment Notification Service",
    text: "We also provide a Schengen Visa Appointment Notification Service, designed to keep our users informed about available appointment slots in real-time. This service is hosted via a free-to-join WhatsApp group, where members receive timely updates about newly available visa appointments for Schengen countries. Our goal is to ensure that users never miss an opportunity to secure an appointment, especially in times of high demand. Joining is easy and open to everyone—simply click on the link to below to become part of our group:",
    image: SchengenVisaAppointment,
  },
];

const Services = () => {
  return (
    <div className="font-['Albert_Sans']">
      <div className="w-full relative">
        <div className="flex justify-center items-center w-7/12 lg:w-1/2 h-32 lg:h-96 bg-[#00c3aa]">
          <h1 className="text-3xl lg:text-8xl font-bold text-white w-1/2 text-center font-['Albert_Sans'] mr-12 lg:mr-0">
            OUR SERVICES
          </h1>
        </div>
        <div className="w-full flex justify-end items-end w-1/2">
          <img
            src={require("../assets/Images/services/Lofoten Islands.webp")}
            alt="Mountain Image"
            className="lg:w-[783px] h-32 lg:h-96 flex justify-end absolute right-[0] top-[35px] object-cover"
          ></img>
        </div>
      </div>
      <div className="flex justify-center text-center text-[#011e41] text-xl ">
        <p className="w-11/12 pt-20 pb-0 lg:p-20 text-base lg:text-2xl text-[#011e41] font-normal mb-8 lg:mb-0">
          At The Flying Panda, we offer an extensive suite of travel-related
          services, meticulously designed to facilitate a seamless and efficient
          travel experience. With our expertise, attention to detail, and
          personalized approach, we ensure every aspect of your journey is
          handled with precision, allowing you to travel with confidence. Below
          is a comprehensive overview of our services, showcasing our dedication
          to meeting every possible need a traveler may have:
        </p>
      </div>
      <div className="space-y-12 flex flex-col items-center">
        {services.map((service, index) => (
          <div
            key={service.id}
            className={`flex flex-col md:flex-row w-11/12  ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            } items-center gap-8 lg:pb-20`}
          >
            <div className="w-full md:w-4/12 flex justify-center ">
              <img
                src={service.image}
                alt={`${service.title} image`}
                className="-8 w-5/12 lg:w-10/12"
              />
            </div>
            <div className="w-full md:w-8/12">
              <h2 className="text-[28px] lg:text-[45px] leading-tight uppercase font-extrabold text-[#011e41] mb-9 text-center lg:text-left">
                {service.title}
              </h2>
              <p className="text-base lg:text-2xl text-[#011e41] font-normal leading-tight text-center lg:text-left">
                {service.text}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="lg:ms-16 mt-5 md:mt-0 text-center lg:text-left mb-4 md:mb-0">
        <a
          href="https://chat.whatsapp.com/BQGYXuWyhA78qTeGrw2qso"
          target="_blank"
          className="text-[#00c3aa] underline text-base lg:text-2xl"
        >
          Click here to join our WhatsApp Group
        </a>
      </div>
    </div>
  );
};

export default Services;
