import React, { useState } from "react";
import jsonData from "../data/questions.json";

const SearchComponent = () => {
  const [data] = useState(jsonData); // Initialize state with imported JSON
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [showEmptySearchMessage, setShowEmptySearchMessage] = useState(false);

  // Handle search
  const handleSearch = () => {
    if (!searchTerm.trim()) {
      setResults([]);
      setNotFound(false);
      setShowEmptySearchMessage(true); // Show empty search message
      return;
    }

    setShowEmptySearchMessage(false); // Hide empty search message if valid term provided

    const searchResults = [];
    Object.entries(data).forEach(([category, questions]) => {
      questions.forEach((item) => {
        if (
          item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.answer.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          searchResults.push({ ...item, category });
        }
      });
    });

    setResults(searchResults);
    setNotFound(searchResults.length === 0); // Set `notFound` only if no results are found
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {/* Search Input */}
      <div className="flex items-center justify-center w-[85vw] md:w-[50vw]">
        <input
          type="text"
          placeholder="Type keywords to find answers"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={handleSearch}
          className="w-full mt-11 lg:px-4 lg:py-2 border-none rounded-md bg-[#E8E8E8] placeholder:ps-10"
        />
      </div>

      {/* Search Button */}
      <div className="flex justify-center mt-5">
        <button
          onClick={handleSearch}
          className="text-[#011e41] border-none text-xl font-medium bg-[#00c3aa] p-1 px-10 rounded-xl mb-5"
        >
          Search
        </button>
      </div>

      {/* Results Section */}
      <div className="max-w-5xl mx-auto bg-white rounded-md p-6">
        {/* Empty Search Message */}
        {showEmptySearchMessage && (
          <p className="text-red-600 text-center font-medium">
            Please enter a keyword for search.
          </p>
        )}

        {/* No Results Message */}
        {notFound && !showEmptySearchMessage && (
          <p className="text-gray-600 text-center">No results found.</p>
        )}

        {/* Results */}
        {!showEmptySearchMessage &&
          results.map((result, index) => (
            <div
              key={index}
              className={`pb-4 cursor-pointer mb-4 border-2 border-[#00c3aa] ${
                index % 2 === 0 ? "bg-gray-100" : "bg-white"
              }`}
            >
              {/* Question */}
              <div className="flex justify-between items-center  p-3 px-10 bg-[#00c3aa]">
                <p className="text-gray-800 font-medium text-xl">
                  <span>{index + 1}. </span>
                  {result.question}
                </p>
              </div>

              {/* Answer */}
              <div className="mt-2 text-[#011e41] text-base px-10 py-5 ">
                <p>
                  <strong>Category: </strong>
                  {result.category}
                </p>
                <p>
                  <strong>Answer: </strong>
                  {result.answer}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SearchComponent;
