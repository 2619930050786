import React, { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { Shimmer } from "../component/Shimmer";

const Whatwedo = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-col md:flex-row items-center justify-between md:p-4 md:p-8 bg-white lg:min-h-screen">
        {/* Image Section */}
        <div className="mt-0  md:hidden block ">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/Travelergroup.webp")}
              alt="Group of happy travelers"
              className="lg:w-10/12 md:h-[400px]"
            />
          </Suspense>
        </div>
        <div className="md:w-6/12 text-center md:flex   md:text-left justify-center p-3 md:p-0">
          <div className="md:w-10/12">
            <h2 className="text-[28px] lg:text-[45px]  mt-7 font-extrabold text-[#011e41] mb-8 md:mb-12   md:w-2/3  lg:w-full">
              WHAT WE DO
            </h2>
            <p className="text-base lg:text-2xl px-4 md:px-0 md:pr-10  sm:pt-[20px]  text-[#011e41] font-normal leading-[1.2] mb-10 md:mb-16">
              At The Flying Panda, we simplify the travel experience by
              providing comprehensive visa solutions, including visa
              appointments, documentation, consultations, and personalized
              itineraries. In addition, we offer hotel and travel bookings,
              customized private and group tours, travel insurance, and passport
              services. We aim to ensure a stress-free travel experience,
              offering tailored support for Schengen, US, and global visa
              applications.
            </p>

            <button
              onClick={() => navigate("/aboutus")}
              className="w-8/12 md:w-5/12 flex justify-center p-2 ms-12 md:ms-0 border-2 border-[#00c3aa] text-[#00c3aa] rounded-xl md:rounded-lg font-medium transition hover:bg-[#00c3aa] hover:text-white"
            >
              Learn More About Us
              <FiArrowRight className=" ml-2 w-6 h-6 text-[#00c3aa]" />
            </button>
          </div>
        </div>

        {/* Image Section */}
        <div className="md:w-1/2 mt-6 md:mt-0 md:pl-8 hidden md:block ">
          <Suspense fallback={<Shimmer />}>
            <img
              src={require("../assets/Images/Travelergroup.webp")}
              alt="Group of happy travelers"
              className="lg:w-10/12 lg:h-[500px]"
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Whatwedo;
